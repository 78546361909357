

.faq {
    margin-top: 54px;
    @include mq {
        margin-top: 100px;
    }
    &__textarea {
        margin-bottom: 43px;
        @include mq {
            margin: 38px 0 53px;
        }
    }
    &__container {
        @include container;
        @include mq {
            max-width: 920px;
        }
    }
    &__list {
        margin-bottom: 37px;
        @include mq {
            margin-bottom: 102px;
            padding: 0px 10px;
        }
    }
    &__article {
        border-top: 1px dotted #b6b6b6;
        padding: 22px 0px 3px;
        @include mq {
            &:nth-child(1) {
                padding: 30px 0px;
            }
            &:nth-child(2) {
                padding: 39px 0px 28px;
            }
            &:nth-child(3) {
                padding: 49px 0px 6px;
            }
        }
        &:last-child {
            border-bottom: 1px dotted #b6b6b6;
        }
    }
    &__img {
        width: 58px;
        @include mq {
            width: 94px;
            flex: 0 0 94px;
        }
    }
    &__que {
        align-items: center;
        display: flex;
        margin-bottom: 17px;
        @include mq {
            margin-bottom: 30px;
        }
    }
    &__txt {
        margin-left: 5px;
        line-height: 20px;
        @include mq {
            line-height: 32px;
        }
        &--que {
            font-size: 12px;
            font-weight: bold;
            @include mq {
                font-size: 20px;
            }
        }
        &--ans {
            font-size: 11px;
            @include mq {
                font-size: 15px;
            }
        }
    }
    &__ans {
        align-items: flex-start;
        display: flex;
        margin-bottom: 10px;
        @include mq {
            margin-left: 90px;
        }
    }
    &__btn {
        margin-bottom: 40px;
        @include mq {
            margin-bottom: 117px;            
        }
    }
}

.content {
    &__faq {
        padding: 0;
    }
    &__ttl {
        &--faq {
            margin-bottom: 42px;
            letter-spacing: 0.1em;
        }
    }
}

/************************
------- about -------
************************/
#aboutbridgecareer {
    .page-ttl{
        &__en {
            @include mq {
                width: auto;
            }
        }
        &__ja{
            background-image: url(../img/icon_hand.png);
        }
    }
    .content {
        &__ttl-icon{
            background-image: url(../img/icon_hand.png);
        }
    }
}
