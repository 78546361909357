/************************
  ------- job -------
  ************************/

.jobsearch {
  &__page-ttl {
    &--en {
      height: 17px;
      @include mq {
        height: auto;
      }
    }
    &--ja {
      background-image: url(../img/icon_ttl-search.png);
    }
  }
  &__search {
    @include container;
    padding: 0;
    @include mq {
      margin-top: 75px;
      margin-bottom: 68px;
    }
    &__container {
      max-width: 920px;
    }
    &__menu {
      padding: 0;
    }
  }
  &__ragistration {
    margin: 6px 0 60px;
    @include mq {
      margin: 43px 0 93px;
    }
  }
  .content {
    margin-top: 0;
  }
}

.list-top {
  display: flex;
  padding-bottom: 15px;
  &__pager {
    display: flex;
    width: 84px;
    justify-content: space-between;
    margin-left: auto;
    @include mq {
      width: 84px;
    }
  }
}

.pager-arrow {
  display: block;
  content: "";
  width: 34px;
  height: 34px;
  background-size: contain;
  background-repeat: no-repeat;
  @include mq {
    width: 40px;
    height: 40px;
  }
  &--left {
    background-image: url(../img/icon_pager_prev.png);
  }
  &--right {
    background-image: url(../img/icon_pager_next.png);
  }
}

.list-bottom {
  display: flex;
  padding-bottom: 15px;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 25px;
  @include mq {
    max-width: 100%;
  }
  &__pager {
    display: flex;
    width: calc(100% - -20px);
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    @include mq {
      margin-left: auto;
      margin-right: 0;
      justify-content: flex-end;
      width: auto;
    }
  }
  &__pager-arrow {
    width: 46px;
    height: 46px;
    @include mq {
      width: 38px;
      height: 38px;
    }
  }
}

.pager-link {
  font-size: 14px;
  color: #555;
  position: relative;
  display: block;
  padding: 0 0.5em;
  top: -5px;
  @include mq {
    font-size: 16px;
    display: block;
    padding: 0 10px;
    position: relative;
    top: -0.25em;
    color: #1766c2;
  }
  &.pager-link__enable {
    pointer-events: none;
    opacity: 0.2;
    color: #333333;
    &:after {
      display: none;
    }
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #555;
    @include mq {
      display: none;
    }
  }
  &__prev {
    padding: 0 0 0 0;
    position: static;
    @include mq {
      padding-left: 0;
    }
    &:after {
      display: none;
    }
    // &.pager-link__enable{
    // }
  }
  &__next {
    padding: 0 0 0 0;
    position: static;
    @include mq {
      padding-right: 0;
    }
    &:after {
      display: none;
    }
  }
}

.job-count {
  font-size: 16px;
  color: #3eb0ff;
  display: inline-block;
  margin-right: 5px;
  @include mq {
    font-size: 25px;
  }
  &__txt {
    &--pc {
      display: none;
      @include mq {
        display: inline-block;
      }
    }
    &--sp {
      display: inline-block;
      @include mq {
        display: none;
      }
    }
  }
}

.list-box {
  color: #555;
  @include mq {
    // margin-bottom: -35px;
  }
}

.list-item {
  border-top: 2px solid #3eb0ff;
  margin-bottom: 40px;
  @include mq {
    border-top: 3px solid #3eb0ff;
    margin-bottom: 60px;
  }
  &:last-of-type{
    @include mq {
      margin-bottom: 0;
    }
    }
  &__ttl {
    display: block;
    align-items: center;
    color: #3eb0ff;
    font-size: 13px;
    padding: 10px 10px 5px 10px;
    margin-bottom: 3px;
    line-height: 20px;
    font-weight: 600;
    @include mq {
      font-size: 22px;
      padding: 16px 30px 7px 54px;
      margin-bottom: 9px;
      line-height: 30px;
    }
  }
  &__tag {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    margin-right: -5px;
    margin-bottom: 5px;
    justify-content: flex-start;
    @include mq {
      padding-left: 52px;
      margin-bottom: 18px;
      justify-content: flex-start;
    }
  }
  &__details {
    background-color: #f4eee0;
    padding: 12px 10px 20px 10px;
    @include mq {
      padding: 27px 50px 14px 48px;
    }
  }
}
.tag-item {
  display: block;
  padding: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #1776c2;
  font-weight: bold;
  min-width: 63px;
  border: 1px solid #1776c2;
  text-align: center;
  border-radius: 3px;
  font-size: 12px;
  @include mq {
    padding: 0;
    min-width: 68px;
    font-size: 13px;
    padding: 0 7px;
  }
  &--new {
    color: #ff4985;
    background-color: #ffff00;
    border: none;
  }
}

.ttl-link {
  color: inherit;
  display: inline;
  &:hover {
    text-decoration: underline;
    // border-bottom: 1px solid;
    opacity: 1;
  }
  &:visited {
    color: #a0a0a0;
  }
}

.details-inner {
  &__top {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    align-items: center;
    @include mq {
      padding: 0 6px;
      align-items: flex-start;
    }
  }
  &__caregory {
    display: block;
    text-align: center;
    font-size: 12px;
    width: 22px;
    flex-shrink: 0;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    @include mq {
      font-size: 14px;
      width: 23px;
      height: 23px;
      line-height: 23px;
      flex: none;
    }
    &--a {
      color: #fff;
      background-color: #3eb8b4;
    }
  }
  &__txt {
    display: flex;
    color: #3eb0ff;
    align-items: center;
    line-height: 1.35;
    @include mq {
      align-items: flex-start;
      padding-left: 10px;
    }
    &::before {
      display: block;
      content: "";
      width: 1px;
      height: 15px;
      border-left: 1px solid #3eb0ff;
      margin-right: 8px;
      @include mq {
        height: 23px;
        border-left: 2px solid #3eb0ff;
        margin-right: 50px;
      }
    }
    &--district {
      flex: 0 1 auto;
      font-size: 13px;
      margin-left: 10px;
      font-weight: 600;
      @include mq {
        font-size: 18px;
        padding-left: 0;
        margin-left: 27px;
        font-weight: 600;
      }
    }
    &--pay {
      flex: 1 0 auto;
      max-width: 12em;
      font-size: 14px;
      margin-left: 10px;
      font-weight: 600;
      @include mq {
        max-width: none;
        font-size: 18px;
        padding-left: 0;
        margin-left: 50px;
        margin-top: 0;
        font-weight: 600;
      }
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  &__btn {
    margin-top: 14px;
    width: 282px;
    margin: 14px auto 0;
    @include mq {
      width: 516px;
      margin: 0 auto 0;
      justify-content: space-between;
    }
    &--pc {
      display: none;
      @include mq {
        display: flex;
      }
    }
    &--sp {
      display: flex;
      justify-content: center;
      width: calc(100% - 10px);
      a {
        padding: 0 5px;
      }
      @include mq {
        display: none;
      }
    }
  }
  &__left {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
    @include mq {
      width: calc(100% - 290px);
      max-width: 530px;
      margin-left: 7px;
    }
  }
}

.details-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  border-top: 1px dotted #c5c5c5;
  &:last-of-type {
    border-bottom: 1px dotted #c5c5c5;
  }
  &__ttl {
    width: 77px;
    font-weight: bold;
    padding: 5px 0 5px 2px;
    font-size: 12px;
    @include mq {
      width: 100px;
      padding: 6px 0 2px;
      // margin-bottom: 6px;
      font-size: 14px;
    }
  }
  &__data {
    width: calc(100% - 80px);
    padding: 5px 0;
    font-size: 12px;
    @include mq {
      width: calc(100% - 100px);
      padding: 7px 0 7px 10px;
      font-size: 14px;
      // margin-bottom: 6px;
    }
  }
}

.job-id {
  width: 100%;
  font-weight: bold;
  color: #fc74a1;
  margin-top: 10px;
  @include mq {
    order: 3;
  }
}

.job-img {
  order: -1;
  width: 200px;
  margin: 0 auto 7px;
  @include mq {
    width: 275px;
    margin: 0 0 0 auto;
    order: 0;
  }
}

.link-detail {
  @include mq {
    width: 238px;
  }
}
