.header {
  align-items: center;
  background: rgba(93, 185, 249, 0.8);
  display: flex;
  height: auto;
  min-height: 66px;
  position: relative;
  @include mq {
    align-items: flex-start;
    width: 980px;
    height: 150px;
    padding: 20px 0;
    border-radius: 5px;
    margin: 25px auto 0;
  }
  &__container {
    @include container;
    padding: 0 8px;
    @include mq {
      height: 100%;
      padding: 0 20px;
    }
  }
  &__contents {
    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__text {
    flex: none;
    font-size: 10px;
    font-weight: bold;
    line-height: 10px;
    margin: 0 0 10px;
    order: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    @include mq {
        font-size: 11px;
        line-height: 13px;
        margin: 0;
        width: auto;
    }
  }
  &__logo {
    align-self: center;
    flex: none;
    //height: 23px;
    //width: 135px;
    width: 38%;
    height: auto;
    @include mq {
      order: 2;
      height: 44px;
      width: 258px;
    }
  }
  &__link {
    display: block;
    // &:hover {
    //   opacity: 1;
    // }
  }
  &__img {
    display: block;
    height: inherit;
    width: inherit;
  }
  &__btn {
    align-self: flex-end;
    margin: 0 6px 0 auto;
    width: 40%;
    @include mq {
      align-items: center;
      align-self: flex-start;
      display: flex;
      margin: 0;
      order: 1;
      justify-content: space-between;
      width: auto;
    }
  }
  &__contact {
    text-align: right;
  }
  &__phone {
    display: block;
    height: 21px;
    width: 100%;
    height: auto;
    @include mq {
      width: auto;
      height: 38px;
      pointer-events: none;
    }
    // &:hover {
    //   opacity: 1;
    // }
  }
  &__saiyou {
    display: none;
    @include mq {
      display: block;
      margin-left: 8px;
    }
    // &:hover {
    //   opacity: 1;
    // }
  }
  &__touroku {
    display: none;
    @include mq {
      display: block;
      margin-left: 6px;
    }
    // &:hover {
    //   opacity: 1;
    // }
  }
  &__nav {
    height: 100vh;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    transform: translateX(100vw);
    transition: transform .5s;
    width: 100%;
    z-index: 100;
    @include mq {
      align-self: flex-end;
      flex: none;
      height: auto;
      order: 3;
      overflow: visible;
      position: relative;
      transform: translateX(0);
      transition: none;
      width: auto;
    }
    &.open {
      transform: translateX(0);
    }
  }
}

.nav-btn {
  background: url(../img/icon_nav_open.png) no-repeat center;
  background-size: cover;
  display: block;
  height: 32px;
  width: 35px;
  @include mq() {
    display: none;
  }
  &--close {
    display: block;
    content: "";
    position: absolute;
    left: -66px;
    top: 182px;
    width: 40px;
    height: 100px;
    background-image: url(../img/icon_nav_close.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.nav {
  display: block;
  width: calc(450 / 640 * 100vw);
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  @include mq {
    display: none;
  }
}

.nav-list {
  font-size: 11px;
  color: #333;
  line-height: 29px;
  border-left: 1px solid #1bc3ed;
  border-right: 1px solid #1bc3ed;
  border-top: 1px solid #1bc3ed;
}
.nav-item {
  border-bottom: 1px solid #1bc3ed;
  &__link {
    display: flex;
    align-items: center;
    background-position: left 10px center;
    background-size: 12px;
    background-repeat: no-repeat;
    padding: 8px 0 8px 30px;
    // &:hover {
    //   opacity: 1;
    // }
    &--search {
      background-image: url(../img/icon_search_bk.png);
    }
    &--feature {
      background-image: url(../img/icon_feature_ttl.png);
    }
    &--info {
      background-image: url(../img/icon_ttl-info.png);
    }
    &--kigyou {
      background-image: url(../img/icon_jinzai.png);
    }
    &--search {
      background-image: url(../img/icon_search_bk.png);
    }
    &--mail {
      background-image: url(../img/icon_mail.png);
    }
    &--hand {
      background-image: url(../img/icon_hand.png);
    }
  }
  &__link-after {
    cursor: pointer;
    display: block;
    height: 29px;
    margin: 0 0 0 auto;
    width: 29px;
    &--arrow {
      background: url(../img/icon_menu_link.png) no-repeat center;
      background-size: 8px auto;
    }
    &--open {
      background: url(../img/icon_menu_open.png) no-repeat center;
      background-size: 13px auto;
    }
    &--close {
      background: url(../img/icon_menu_close.png) no-repeat center;
      background-size: 13px auto;
    }
  }
}
.nav-sublist {
  background-color: #e1f4fd;
  color: #555;
  display: none;
}
.nav-subitem {
  border-top: 1px solid #1bc3ed;
  &__link{
    display: flex;
    padding: 8px 0 8px 35px;
    align-items: center;
  }
}

.nav-overlay {
  content: "";
  display: block;
  width: 0;
  height: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.5s;
}
.nav-overlay.open {
  width: 100%;
  height: 100%;
  opacity: 1;
  @include mq {
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.nav-pc {
  display: none;
  @include mq {
    display: block;
  }
  &__list {
    display: flex;
  }
  &__item {
    position: relative;
    &::before,
    &:last-child::after {
      background: #fff;
      bottom: 0;
      content: "";
      height: 30px;
      left: 0;
      margin: auto;
      position: absolute;
      top: 0;
      width: 1px;
    }
    &:last-child::after {
      left: auto;
      right: 0;
    }
    &:hover {
      .nav-pc__link::after {
        background: #fff;
      }
      .sub-nav-pc {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__link {
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    line-height: 40px;
    display: block;
    padding: 0 28px;
    &:hover {
      opacity: 1;
    }
    &:after {
      background: transparent;
      bottom: 0;
      content: "";
      height: 3px;
      left: 28px;
      margin: auto;
      position: absolute;
      right: 28px;
      transition: background .3s;
    }
  }
}

.sub-nav-pc {
    background: #fff;
    top: 51px;
    border: 1px solid #1d92e3;
    border-radius: 10px;
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    transition: opacity .3s, visibility .3s;
    visibility: hidden;
    width: 230px;
    &::before,
    &::after {
      border-color: transparent transparent #1d91e3 transparent;
      border-style: solid;
      border-width: 0 7.5px 10px 7.5px;
      content: "";
      height: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: -10px;
      width: 0;
    }
    &::after {
      border-color: transparent transparent #fff transparent;
      top: -9px;
    }
  &__item {
    margin: 0 5px;
    &:not(:last-child) {
      border-bottom: 1px dotted #1d92e3;
    }
  }
  &__link {
    color: #1d92e3;
    display: block;
    font-size: 14px;
    line-height: 40px;
    margin: 0 -5px;
    text-align: center;
    &:hover {
      background: #eaf6ff;
      opacity: 1;
    }
    &_child {
      &_first {
        border-radius: 10px 10px 0 0;
      }
      &_last {
        border-radius: 0 0 10px 10px;
      }
    }
  }
}

/************************
  ------- footer -------
  ************************/

.top-footer {
  margin: 0 0 calc(126 / 640 * 100vw);
  @include mq {
    background: #e0f2fe;
    font-size: 13px;
    margin: 0 0 103px;
  }
  &__nav {
    &--pc {
      display: none;
      @include mq {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 980px;
        padding: 26px 0;
        width: 100%;
      }
    }
    &--sp {
      @include mq {
        display: none;
      }
    }
  }
}
.footer-nav-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include mq{
    display: block;
  }
}
.footer-nav-item {
  display: block;
  flex: none;
  width: calc(50% - 1px);
  @include mq {
    background-position: left top;
    border: none;
    width: 100%;
  }
  &:not(:nth-last-child(-n+2)) {
    margin: 0 0 2px;
  }
  &__link {
    background: #e0f2fe url(../img/icon_arrow_bl.png) no-repeat left 10px center;
    background-size: 7px auto;
    color: #077fd2;
    display: block;
    font-size: 10px;
    font-weight: bold;
    line-height: 13px;
    padding: 13px 0 13px 30px;
    @include mq {
      background: transparent url(../img/icon_arrow_bl.png) no-repeat left 3px top 6px;
      background-size: 7px auto;
      font-size: 13px;
      line-height: 28px;
      padding: 0 20px;
    }
  }
  &__sublist {
    color: #077fd2;
  }
}

.footer-nav-sublist {
  padding: 0 0 0 36px;
}

.footer-nav-subitem {
  &__link {
    color: #077fd2;
    display: block;
    font-size: 13px;
    line-height: 28px;
  }
}

.copy-right {
  background: #3eb0ff;
  color: #fff;
  font-size: 10px;
  line-height: 15px;
  padding: 10px 0;
  text-align: center;
  @include mq{
    font-size: 13px;
    line-height: 18px;
    padding: 11px 0;
  }
}

/************************
  ------- bottom-nav -------
  ************************/

.bottom-nav {
  bottom: 0;
  display: block;
  left: 0;
  margin: 0 auto;
  position: fixed;
  width: 100%;
  z-index: 1;
  height: calc(126 / 640 * 100vw);
  opacity: 0;
  transition:opacity .75s ease-out;
  &.fixed{
    opacity:1;
    @include mq {
      opacity:1;
    }
  }
  @include mq {
    height: auto;
    background: rgba(93, 185, 249, 0.8);
    padding: 20px 0;
    opacity: 0;
  }
}
.bottom-nav-list {
  margin: 0 -2px;
  display: flex;
  justify-content: space-between;
  @include mq {
    margin: 0 auto;
    width: 980px;
  }
}
.bottom-nav-item {
  flex: none;
  padding: 0 2px;
  width: 25%;
  @include mq {
    padding: 0;
    width: 234px;
  }
  &__link {
    display: block;
  }
  &__img {
    display: block;
    width: 100%;
  }
  &:nth-child(2) {
    position: relative;
  }
  &__badge {
    background-color: #e4007f;
    border-radius: 4.6875vw;
    color: #fff;
    display: inline-block;
    font-size: calc(30 / 640 * 100vw);
    height: calc(42 / 640 * 100vw);
    line-height: calc(42 / 640 * 100vw);
    min-width: calc(42 / 640 * 100vw);
    padding: 0 calc(2 / 640 * 100vw);
    position: absolute;
    right: calc(4 / 640 * 100vw);
    text-align: center;
    top: calc(3 / 640 * 100vw);
    @include mq {
        border-radius: 6.5625vw;
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        min-width: 24px;
        right: 5px;
        top: -10px;
        z-index:3;
    }
    &--many {
        min-width: 24px;
        width: auto;
        border-radius: 24px;
        padding: 0 2px;
    }
  }
}
.is-fixed{
  display: none;
}