/************************
  ------- job -------
  ************************/

.browsehistory {
  &__page-ttl {
    &--en {
      height: 19px;
      @include mq {
        height: auto;
      }
    }
    &--ja {
      background-image: url(../img/icon_clock.png);
    }
  }
  &__search {
    @include container;
    @include mq {
      margin-top: 75px;
      margin-bottom: 68px;
    }
  }
  &__ragistration{
    margin: 6px 0 60px;
    @include mq{
      margin: 43px 0 70px;
    }
  }
}
