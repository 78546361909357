/************************
  ------- for-recruitment-staff -------
  ************************/

.contact-business {
  &__page-ttl {
    &--en {
      height: 15px;
      @include mq {
        height: auto;
      }
    }
    &--ja {
      background-image: url(../img/icon_jinzai.png);
      background-size: 11px;
      @include mq {
        background-size: 26px;
        margin-left: 27px;
        line-height: 36px;
      }
    }
  }
  &__content {
    @include mq {
      margin-top: 93px;
    }
  }
  &__content-ttlicon {
    background-image: url(../img/icon_jinzai.png);
    background-size: 26px;
    @include mq {
      padding-left: 50px;
      margin-left: 4px;
    }
  }
}
