/************************
  ------- job -------
  ************************/

.job {
  &__page-ttl {
    &--en {
      height: 20px;
      @include mq {
        height: auto;
      }
    }
    &--ja {
      background-image: url(../img/icon_recommend_ttl.png);
      @include mq {
        background-size: 30px;
        margin-left: 32px;
        padding-left: 51px;
      }
    }
  }
  &__content {
    margin-top: 7px;
    @include mq {
      margin-top: 106px;
    }
  }
  &__list-box {
    margin: 0;
  }
  &__list-item {
    margin: 0;
  }
  &__details-middle {
    display: flex;
    flex-wrap: wrap;
  }
  &__details-bottom {
    width: 100%;
    margin: 0 7px 0 3px;
    @include mq {
      margin: 0 7px;
    }
  }
  &__job-id {
    order: 1;
    font-size: 10px;
    @include mq {
      margin-bottom: 12px;
      order: 3;
      font-size: 14px;
    }
    span{
      font-weight: 600;
      padding-left: 2em;
      @include mq {
        padding-left: 2em;
        font-weight: 600;
      }
    }
  }
  &__job-img {
    order: -1;
    @include mq {
      order: 0;
    }
  }
  &__job-point {
    order: 1;
    margin-top: 20px;
  }
  &__ragistration {
    margin: 25px 0 40px;
    @include mq {
      margin: 101px 0 111px;
    }
  }
  &__details-list {
    border-bottom: none;
    border-top: 1px dotted #c5c5c5;
    &:last-of-type {
      border-bottom: none;
    }
  }
  &__entrybtn {
    text-align: center;
    width: 206px;
    @include mq {
      width: 320px;
    }
  }
  &__list-item-details {
    padding: 12px 6px 28px 6px;
    @include mq {
      padding: 27px 50px 50px 48px;
    }
  }
  .details-inner {
    &__left {
      @include mq {
        min-height: 200px;
      }
    }
    &__bottom {
      width: 100%;
      border-bottom: 1px dotted #c5c5c5;
      @include mq {
        margin-left: 7px;
      }
    }
  }
}

.job-point {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 14px;
  @include mq {
    margin-top: 34px;
    margin-bottom: 0;
  }
  &__ttl {
    background-color: #88cbff;
    width: 100%;
    height: 37px;
    padding: 3px 12px;
    border-radius: 3px 3px 0 0;
    @include mq {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px 0 0 5px;
      padding: 16px 0 0;
      width: 168px;
      height: auto;
      min-height: 162px;
    }
    &--pc {
      display: none;
      @include mq {
        display: block;
      }
    }
    &--sp {
      width: 100%;
      height: 100%;
      @include mq {
        display: none;
      }
    }
  }
  &__txt {
    padding: 15px;
    width: 100%;
    font-size: 12px;
    li{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      &:before{
        content:"・";
        display: block;
        flex: 0 0 1em;
        max-width: 1em;;
      }
    }
    @include mq {
      flex: 1;
      padding: 24px 42px;
      font-size: 14px;
    }
  }
}
