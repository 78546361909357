@charset "UTF-8";
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary { margin: 0; padding: 0; border: 0; font-size: 100%; font-weight: normal; vertical-align: baseline; background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary { display: block; }

/* Handle box-sizing while better addressing child elements: http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img, object, embed { max-width: 100%; }

/* Note: keeping IMG here will cause problems if you're using foreground images as sprites. In fact, it *will* cause problems with Google Maps' controls at small size. If this is the case for you, try uncommenting the following: #map img { max-width: none; } */
/* force a vertical scrollbar to prevent a jumpy page */
html { overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted. you'll have to restore the bullets within content, which is fine because they're probably customized anyway */
ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

a { margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent; }

del { text-decoration: line-through; }

abbr[title], dfn[title] { border-bottom: 1px dotted #000; cursor: help; }

/* tables still need cellspacing="0" in the markup */
table { border-collapse: collapse; border-spacing: 0; }

th { font-weight: bold; vertical-align: bottom; }

td { font-weight: normal; vertical-align: top; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

input, select { vertical-align: middle; }

pre { white-space: pre; /* CSS2 */ white-space: pre-wrap; /* CSS 2.1 */ white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */ word-wrap: break-word; /* IE */ }

input[type="radio"] { vertical-align: text-bottom; }

input[type="checkbox"] { vertical-align: bottom; }

.ie7 input[type="checkbox"] { vertical-align: baseline; }

.ie6 input { vertical-align: text-bottom; }

select, input, textarea { font: 99% sans-serif; }

table { font-size: inherit; font: 100%; }

small { font-size: 85%; }

strong { font-weight: bold; }

td, td img { vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup { font-size: 75%; line-height: 0; position: relative; }

sup { top: -0.5em; }

sub { bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp { font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable, label, input[type=button], input[type=submit], input[type=file], button { cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea { margin: 0; }

/* make buttons play nice in IE */
button, input[type=button] { width: auto; overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img { -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover (commented out as usage is rare, and the filter syntax messes with some pre-processors) .ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));} */
/* let's clear some floats */
.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }

.clearfix:after { clear: both; }

.clearfix { zoom: 1; }

* { margin: 0; padding: 0; box-sizing: border-box; color: inherit; font-size: inherit; text-decoration: none; }

img { max-width: 100%; max-height: 100%; }

ul { list-style-type: none; }

body { font-family: 'Noto Sans JP', sans-serif; font-weight: 400; font-style: normal; color: #333; height: 100%; overflow-x: hidden; position: relative; }

@media screen and (min-width: 769px) { body { min-width: 980px; } }

a:hover { opacity: 0.8; }

input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea { background-color: #fff; color: #666; border: 1px solid #ccc; border-radius: 3px; padding: 12px 10px; outline: none; box-shadow: none; -webkit-appearance: none; -moz-appearance: none; appearance: none; }

@media screen and (min-width: 769px) { input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea { padding: 3px 10px; } }

select { position: relative; background-color: #fff; background-image: url(../img/icon_arrow_select_bl.jpg); background-position: right center; background-repeat: no-repeat; background-size: contain; padding: 3px 24px 3px 5px; border-radius: 3px; outline: none; }

@media screen and (min-width: 769px) { select { padding: 3px 40px 3px 5px; } }

select::before { position: absolute; top: 0.8em; right: 0.9em; width: 0; height: 0; padding: 0; content: ''; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #666666; pointer-events: none; }

select { border: 1px solid #ccc; padding: 0.4rem 1rem; -moz-appearance: none; -webkit-appearance: none; }

pre { background: #eee; font-family: "Courier 10 Pitch", Courier, monospace; font-size: 15px; font-size: 0.9375rem; line-height: 1.6; margin-bottom: 1.6em; max-width: 100%; overflow: auto; padding: 1.6em; }

input { max-width: 100%; }

.header { align-items: center; background: rgba(93, 185, 249, 0.8); display: flex; height: auto; min-height: 66px; position: relative; }

@media screen and (min-width: 769px) { .header { align-items: flex-start; width: 980px; height: 150px; padding: 20px 0; border-radius: 5px; margin: 25px auto 0; } }

.header__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; padding: 0 8px; }

@media screen and (min-width: 769px) { .header__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .header__container { height: 100%; padding: 0 20px; } }

.header__contents { align-items: flex-start; display: flex; height: 100%; justify-content: space-between; flex-wrap: wrap; }

.header__text { flex: none; font-size: 10px; font-weight: bold; line-height: 10px; margin: 0 0 10px; order: 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width: 100%; }

@media screen and (min-width: 769px) { .header__text { font-size: 11px; line-height: 13px; margin: 0; width: auto; } }

.header__logo { align-self: center; flex: none; width: 38%; height: auto; }

@media screen and (min-width: 769px) { .header__logo { order: 2; height: 44px; width: 258px; } }

.header__link { display: block; }

.header__img { display: block; height: inherit; width: inherit; }

.header__btn { align-self: flex-end; margin: 0 6px 0 auto; width: 40%; }

@media screen and (min-width: 769px) { .header__btn { align-items: center; align-self: flex-start; display: flex; margin: 0; order: 1; justify-content: space-between; width: auto; } }

.header__contact { text-align: right; }

.header__phone { display: block; height: 21px; width: 100%; height: auto; }

@media screen and (min-width: 769px) { .header__phone { width: auto; height: 38px; pointer-events: none; } }

.header__saiyou { display: none; }

@media screen and (min-width: 769px) { .header__saiyou { display: block; margin-left: 8px; } }

.header__touroku { display: none; }

@media screen and (min-width: 769px) { .header__touroku { display: block; margin-left: 6px; } }

.header__nav { height: 100vh; left: 0; overflow: auto; position: fixed; top: 0; transform: translateX(100vw); transition: transform .5s; width: 100%; z-index: 100; }

@media screen and (min-width: 769px) { .header__nav { align-self: flex-end; flex: none; height: auto; order: 3; overflow: visible; position: relative; transform: translateX(0); transition: none; width: auto; } }

.header__nav.open { transform: translateX(0); }

.nav-btn { background: url(../img/icon_nav_open.png) no-repeat center; background-size: cover; display: block; height: 32px; width: 35px; }

@media screen and (min-width: 769px) { .nav-btn { display: none; } }

.nav-btn--close { display: block; content: ""; position: absolute; left: -66px; top: 182px; width: 40px; height: 100px; background-image: url(../img/icon_nav_close.png); background-repeat: no-repeat; background-size: contain; }

.nav { display: block; width: calc(450 / 640 * 100vw); background-color: #fff; position: absolute; top: 0; right: 0; }

@media screen and (min-width: 769px) { .nav { display: none; } }

.nav-list { font-size: 11px; color: #333; line-height: 29px; border-left: 1px solid #1bc3ed; border-right: 1px solid #1bc3ed; border-top: 1px solid #1bc3ed; }

.nav-item { border-bottom: 1px solid #1bc3ed; }

.nav-item__link { display: flex; align-items: center; background-position: left 10px center; background-size: 12px; background-repeat: no-repeat; padding: 8px 0 8px 30px; }

.nav-item__link--search { background-image: url(../img/icon_search_bk.png); }

.nav-item__link--feature { background-image: url(../img/icon_feature_ttl.png); }

.nav-item__link--info { background-image: url(../img/icon_ttl-info.png); }

.nav-item__link--kigyou { background-image: url(../img/icon_jinzai.png); }

.nav-item__link--search { background-image: url(../img/icon_search_bk.png); }

.nav-item__link--mail { background-image: url(../img/icon_mail.png); }

.nav-item__link--hand { background-image: url(../img/icon_hand.png); }

.nav-item__link-after { cursor: pointer; display: block; height: 29px; margin: 0 0 0 auto; width: 29px; }

.nav-item__link-after--arrow { background: url(../img/icon_menu_link.png) no-repeat center; background-size: 8px auto; }

.nav-item__link-after--open { background: url(../img/icon_menu_open.png) no-repeat center; background-size: 13px auto; }

.nav-item__link-after--close { background: url(../img/icon_menu_close.png) no-repeat center; background-size: 13px auto; }

.nav-sublist { background-color: #e1f4fd; color: #555; display: none; }

.nav-subitem { border-top: 1px solid #1bc3ed; }

.nav-subitem__link { display: flex; padding: 8px 0 8px 35px; align-items: center; }

.nav-overlay { content: ""; display: block; width: 0; height: 0; background-color: rgba(0, 0, 0, 0.5); position: absolute; top: 0; left: 0; z-index: 2; opacity: 0; transition: opacity 0.5s; }

.nav-overlay.open { width: 100%; height: 100%; opacity: 1; }

@media screen and (min-width: 769px) { .nav-overlay.open { width: 0; height: 0; opacity: 0; } }

.nav-pc { display: none; }

@media screen and (min-width: 769px) { .nav-pc { display: block; } }

.nav-pc__list { display: flex; }

.nav-pc__item { position: relative; }

.nav-pc__item::before, .nav-pc__item:last-child::after { background: #fff; bottom: 0; content: ""; height: 30px; left: 0; margin: auto; position: absolute; top: 0; width: 1px; }

.nav-pc__item:last-child::after { left: auto; right: 0; }

.nav-pc__item:hover .nav-pc__link::after { background: #fff; }

.nav-pc__item:hover .sub-nav-pc { opacity: 1; visibility: visible; }

.nav-pc__link { color: #fff; cursor: pointer; font-size: 16px; line-height: 40px; display: block; padding: 0 28px; }

.nav-pc__link:hover { opacity: 1; }

.nav-pc__link:after { background: transparent; bottom: 0; content: ""; height: 3px; left: 28px; margin: auto; position: absolute; right: 28px; transition: background .3s; }

.sub-nav-pc { background: #fff; top: 51px; border: 1px solid #1d92e3; border-radius: 10px; left: 50%; opacity: 0; position: absolute; transform: translateX(-50%); transition: opacity .3s, visibility .3s; visibility: hidden; width: 230px; }

.sub-nav-pc::before, .sub-nav-pc::after { border-color: transparent transparent #1d91e3 transparent; border-style: solid; border-width: 0 7.5px 10px 7.5px; content: ""; height: 0; left: 0; margin: auto; position: absolute; right: 0; top: -10px; width: 0; }

.sub-nav-pc::after { border-color: transparent transparent #fff transparent; top: -9px; }

.sub-nav-pc__item { margin: 0 5px; }

.sub-nav-pc__item:not(:last-child) { border-bottom: 1px dotted #1d92e3; }

.sub-nav-pc__link { color: #1d92e3; display: block; font-size: 14px; line-height: 40px; margin: 0 -5px; text-align: center; }

.sub-nav-pc__link:hover { background: #eaf6ff; opacity: 1; }

.sub-nav-pc__link_child_first { border-radius: 10px 10px 0 0; }

.sub-nav-pc__link_child_last { border-radius: 0 0 10px 10px; }

/************************ ------- footer ------- */
.top-footer { margin: 0 0 calc(126 / 640 * 100vw); }

@media screen and (min-width: 769px) { .top-footer { background: #e0f2fe; font-size: 13px; margin: 0 0 103px; } }

.top-footer__nav--pc { display: none; }

@media screen and (min-width: 769px) { .top-footer__nav--pc { display: flex; flex-wrap: wrap; justify-content: space-between; margin: 0 auto; max-width: 980px; padding: 26px 0; width: 100%; } }

@media screen and (min-width: 769px) { .top-footer__nav--sp { display: none; } }

.footer-nav-list { display: flex; flex-wrap: wrap; justify-content: space-between; }

@media screen and (min-width: 769px) { .footer-nav-list { display: block; } }

.footer-nav-item { display: block; flex: none; width: calc(50% - 1px); }

@media screen and (min-width: 769px) { .footer-nav-item { background-position: left top; border: none; width: 100%; } }

.footer-nav-item:not(:nth-last-child(-n+2)) { margin: 0 0 2px; }

.footer-nav-item__link { background: #e0f2fe url(../img/icon_arrow_bl.png) no-repeat left 10px center; background-size: 7px auto; color: #077fd2; display: block; font-size: 10px; font-weight: bold; line-height: 13px; padding: 13px 0 13px 30px; }

@media screen and (min-width: 769px) { .footer-nav-item__link { background: transparent url(../img/icon_arrow_bl.png) no-repeat left 3px top 6px; background-size: 7px auto; font-size: 13px; line-height: 28px; padding: 0 20px; } }

.footer-nav-item__sublist { color: #077fd2; }

.footer-nav-sublist { padding: 0 0 0 36px; }

.footer-nav-subitem__link { color: #077fd2; display: block; font-size: 13px; line-height: 28px; }

.copy-right { background: #3eb0ff; color: #fff; font-size: 10px; line-height: 15px; padding: 10px 0; text-align: center; }

@media screen and (min-width: 769px) { .copy-right { font-size: 13px; line-height: 18px; padding: 11px 0; } }

/************************ ------- bottom-nav ------- */
.bottom-nav { bottom: 0; display: block; left: 0; margin: 0 auto; position: fixed; width: 100%; z-index: 1; height: calc(126 / 640 * 100vw); opacity: 0; transition: opacity .75s ease-out; }

.bottom-nav.fixed { opacity: 1; }

@media screen and (min-width: 769px) { .bottom-nav.fixed { opacity: 1; } }

@media screen and (min-width: 769px) { .bottom-nav { height: auto; background: rgba(93, 185, 249, 0.8); padding: 20px 0; opacity: 0; } }

.bottom-nav-list { margin: 0 -2px; display: flex; justify-content: space-between; }

@media screen and (min-width: 769px) { .bottom-nav-list { margin: 0 auto; width: 980px; } }

.bottom-nav-item { flex: none; padding: 0 2px; width: 25%; }

@media screen and (min-width: 769px) { .bottom-nav-item { padding: 0; width: 234px; } }

.bottom-nav-item__link { display: block; }

.bottom-nav-item__img { display: block; width: 100%; }

.bottom-nav-item:nth-child(2) { position: relative; }

.bottom-nav-item__badge { background-color: #e4007f; border-radius: 4.6875vw; color: #fff; display: inline-block; font-size: calc(30 / 640 * 100vw); height: calc(42 / 640 * 100vw); line-height: calc(42 / 640 * 100vw); min-width: calc(42 / 640 * 100vw); padding: 0 calc(2 / 640 * 100vw); position: absolute; right: calc(4 / 640 * 100vw); text-align: center; top: calc(3 / 640 * 100vw); }

@media screen and (min-width: 769px) { .bottom-nav-item__badge { border-radius: 6.5625vw; font-size: 14px; height: 24px; line-height: 24px; min-width: 24px; right: 5px; top: -10px; z-index: 3; } }

.bottom-nav-item__badge--many { min-width: 24px; width: auto; border-radius: 24px; padding: 0 2px; }

.is-fixed { display: none; }

@media screen and (min-width: 769px) { .main { width: 100%; } }

.key { padding: 0 0 50px; margin: -34px 0 0; }

@media screen and (min-width: 769px) { .key { padding: 0 0 60px; margin: -190px 0 0; } }

.key__thumbnail_display_sp { display: block; }

@media screen and (min-width: 769px) { .key__thumbnail_display_sp { display: none; } }

.key__thumbnail_display_pc { display: none; }

@media screen and (min-width: 769px) { .key__thumbnail_display_pc { display: block; min-height: 607px; min-width: 1247px; }
  .key__thumbnail_display_pc img { height: 660px; object-fit: cover; } }

@media screen and (min-width: 769px) and (max-width: 1199px) { .key__thumbnail_display_pc img { width: 100%; height: 607px; } }

@media screen and (min-width: 769px) and (-ms-high-contrast: none) { .key__thumbnail_display_pc img { height: auto; } }

@media (-ms-high-contrast: none) and (min-width: 769px) { .key__thumbnail_display_pc { min-height: 607px; } }

@media (-ms-high-contrast: none) and (min-width: 1200px) { .key__thumbnail_display_pc { min-height: 654px; } }

.key__link { display: block; margin: calc(-60 / 640 * 100vw) 30px 0; }

@media screen and (min-width: 769px) { .key__link { margin: -98px auto 0; width: 521px; } }

.key__link:hover { opacity: 0.8; }

.key__img { display: block; width: 100%; }

.key__txt { position: absolute; width: 90%; top: 100px; left: 0; right: 0; margin: auto; }

@media screen and (min-width: 769px) { .key__txt { width: 800px; top: 228px; left: 0; right: 0; margin: auto; } }

.key__txt--pc { width: auto; max-width: 380px; }

@media screen and (min-width: 1200px) { .key__txt--pc { max-width: 469px; } }

/************************ ------- search ------- */
.top-search { background: #e0f2fe; padding: 40px 0 58px; }

@media screen and (min-width: 769px) { .top-search { padding: 75px 0 20px; } }

.top-search__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .top-search__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

.top-search__title { height: 18px; margin: 0 0 30px; }

@media screen and (min-width: 769px) { .top-search__title { height: 35px; margin: 0 0 60px; } }

.top-search__img { display: block; }

.top-search .search-form { margin: 0 0 25px; }

@media screen and (min-width: 769px) { .top-search .search-form { border-bottom: 1px dotted #c5c5c5; margin: 0 0 50px; } }

.top-search .search-form__submit { display: block; position: absolute; left: 0; right: 0; margin: auto; }

/************************ ------- recommend ------- */
.recommend { background: url(../img/top/bg_recommend_01_sp.png) no-repeat top center, url(../img/top/bg_recommend_02_sp.png) no-repeat bottom center; background-size: contain, contain; padding: 50px 0; }

@media screen and (min-width: 769px) { .recommend { background: url(../img/top/bg_recommend_01.png) no-repeat top center, url(../img/top/bg_recommend_02.png) no-repeat bottom center; background-size: contain, contain; padding: 120px 0 100px; } }

.recommend__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .recommend__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

.recommend__ttl { height: 18px; margin: 0 0 25px; }

@media screen and (min-width: 769px) { .recommend__ttl { height: 35px; margin: 115px 0 60px; } }

.recommend__img { display: block; }

.recommend__menu { align-items: flex-end; border-bottom: 1px solid #959595; display: flex; margin: 0 0 10px; }

@media screen and (min-width: 769px) { .recommend__menu { margin: 0 0 22px; } }

.recommend__tab-ttl { background: #c5c5c5; border-radius: 6px 6px 0 0; color: #fff; display: block; flex: 1; font-weight: bold; height: 26px; line-height: 26px; max-width: 96px; text-align: center; cursor: pointer; }

@media screen and (min-width: 769px) { .recommend__tab-ttl { font-size: 22px; height: 46px; line-height: 46px; } }

.recommend__tab-ttl:not(:last-child) { margin: 0 2px 0 0; }

@media screen and (min-width: 769px) { .recommend__tab-ttl:not(:last-child) { margin: 0 4px 0 0; } }

.recommend__tab-ttl.active { background: #3eb0ff; height: 30px; line-height: 30px; }

@media screen and (min-width: 769px) { .recommend__tab-ttl.active { height: 56px; line-height: 56px; } }

@media screen and (min-width: 769px) { .recommend__tab-ttl { width: 60px; } }

.recommend__tab-content { border-bottom: 1px solid #959595; display: none; }

.recommend__link { display: none; }

@media screen and (min-width: 769px) { .recommend__link { display: block; margin: 20px 0 0 auto; width: 210px; } }

.recommend-list { display: flex; flex-wrap: wrap; margin: 0 0 5px; }

@media screen and (min-width: 769px) { .recommend-list { margin: 0 0 10px; } }

.recommend-item { background: #e0f2fe; border-radius: 3px; display: block; position: relative; margin: 0 0 10px; width: calc(50% - 10px); }

@media screen and (min-width: 769px) { .recommend-item { padding: 12px 12px 24px; width: 222px; } }

.recommend-item:not(:nth-child(even)) { margin: 0 20px 10px 0; }

@media screen and (min-width: 769px) { .recommend-item:not(:nth-child(4n)) { margin: 0 30px 10px 0; } }

.recommend-item__link { display: block; width: 100%; height: 100%; padding: 12px 12px 24px; }

.recommend-item__link::after { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 1; pointer-events: auto; content: ""; }

.recommend-item__date { display: none; }

@media screen and (min-width: 769px) { .recommend-item__date { display: block; color: #666; display: inline-block; font-size: 15px; line-height: 24px; } }

.recommend-item__caregory { background: #3eb8b4; border-radius: 3px; color: #fff; display: inline-block; font-size: 10px; line-height: 10px; margin: 0 0 4px; padding: 4px; }

@media screen and (min-width: 769px) { .recommend-item__caregory { font-size: 14px; line-height: 14px; margin: 0 0 0 4px; padding: 5px; } }

.recommend-item__tiku { color: #3eb0ff; font-size: 10px; line-height: 14px; }

@media screen and (min-width: 769px) { .recommend-item__tiku { font-size: 15px; line-height: 22px; } }

.recommend-item__jikyu { color: #3eb0ff; font-size: 10px; line-height: 14px; margin: 0 0 4px; }

@media screen and (min-width: 769px) { .recommend-item__jikyu { font-size: 15px; line-height: 22px; } }

.recommend-item__txt { color: #1776c2; display: block; font-size: 10px; line-height: 14px; text-decoration: underline; }

@media screen and (min-width: 769px) { .recommend-item__txt { font-size: 14px; line-height: 20px; } }

/************************ ------- feature ------- */
.top-feature { background: #e0f2fe; padding: 40px 0 50px; }

@media screen and (min-width: 769px) { .top-feature { padding: 80px 0 0; } }

.top-feature__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; position: relative; }

@media screen and (min-width: 769px) { .top-feature__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

.top-feature__img { display: block; }

.top-feature__ttl { height: 18px; margin: 0 0 25px; }

@media screen and (min-width: 769px) { .top-feature__ttl { height: 35px; margin: 0 0 50px; } }

.top-feature__list { display: flex; flex-wrap: wrap; margin: 0 auto; }

@media screen and (min-width: 769px) { .top-feature__list { max-width: none; } }

.top-feature-item { display: block; flex: none; margin: 0 0 14px; width: 50%; }

@media screen and (min-width: 769px) { .top-feature-item { margin: 0; width: 25%; } }

.top-feature-item:nth-child(1) { padding: 0 7px 0 0; }

@media screen and (min-width: 769px) { .top-feature-item:nth-child(1) { padding: 0 10.5px 0 0; } }

.top-feature-item:nth-child(2) { padding: 0 0 0 7px; }

@media screen and (min-width: 769px) { .top-feature-item:nth-child(2) { padding: 0 8px 0 2.5px; } }

.top-feature-item:nth-child(3) { padding: 0 7px 0 0; }

@media screen and (min-width: 769px) { .top-feature-item:nth-child(3) { padding: 0 2.5px 0 8px; } }

.top-feature-item:nth-child(4) { padding: 0 0 0 7px; }

@media screen and (min-width: 769px) { .top-feature-item:nth-child(4) { padding: 0 0 0 10.5px; } }

.top-feature-item__link { background: #fff; box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); display: block; height: 100%; padding: 0 0 16px; position: relative; }

@media screen and (min-width: 769px) { .top-feature-item__link { padding: 0 0 30px; } }

.top-feature-item__link::after { position: absolute; content: ""; display: block; top: 0; right: 0; width: 42px; height: 42px; background-image: url(../img/img_recommend_after.png); background-repeat: no-repeat; background-size: contain; }

.top-feature-item__img { display: block; margin: 0 0 10px; width: 100%; }

@media screen and (min-width: 769px) { .top-feature-item__img { margin: 0 0 20px; } }

.top-feature-item__ttl { color: #3eb0ff; font-size: 13px; font-weight: bold; line-height: 17px; margin: 0 0 5px; padding: 0 10px; }

@media screen and (min-width: 769px) { .top-feature-item__ttl { font-size: 19px; line-height: 29px; padding: 0 30px; } }

.top-feature-item__txt { font-size: 10px; font-weight: bold; line-height: 14px; padding: 0 10px; }

@media screen and (min-width: 769px) { .top-feature-item__txt { font-size: 14px; line-height: 24px; padding: 0 30px; } }

/************************ ------- strength ------- */
.strength { background: linear-gradient(#e0f2fe 50%, #fff 50%); margin: 0 0 50px; }

@media screen and (min-width: 769px) { .strength { margin: 0 0 120px; } }

.strength__thumbnail_display_sp { display: block; }

@media screen and (min-width: 769px) { .strength__thumbnail_display_sp { display: none; } }

.strength__thumbnail_display_pc { display: none; }

@media screen and (min-width: 769px) { .strength__thumbnail_display_pc { display: block; } }

.strength__link { display: block; margin: calc(-60 / 640 * 100vw) 30px 0; }

@media screen and (min-width: 769px) { .strength__link { margin: -50px auto 0; width: 521px; } }

.strength__img { display: block; width: 100%; }

/************************ ------- category-search ------- */
.category-search { margin: 0 0 60px; position: relative; }

@media screen and (min-width: 769px) { .category-search { margin: 0 0 120px; } }

.category-search__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .category-search__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .category-search__container { background: url(../img/bg_category_search.png) no-repeat right 30px center; background-size: contain; } }

.category-search__box { border-bottom: 2px solid #ccc; padding: 10px 0 0; }

@media screen and (min-width: 769px) { .category-search__box { border-bottom-width: 3px; padding: 16px 0 0; } }

.category-search__box:first-child { border-top: 2px solid #ccc; }

@media screen and (min-width: 769px) { .category-search__box:first-child { border-top-width: 3px; } }

.category-search__menu { font-size: 13px; font-weight: bold; line-height: 13px; margin: 0 0 10px; }

@media screen and (min-width: 769px) { .category-search__menu { font-size: 20px; line-height: 20px; margin: 0 0 16px; } }

.category-search__list { display: flex; flex-wrap: wrap; margin: 0 0 4px; padding: 0 0 0 16px; }

@media screen and (min-width: 769px) { .category-search__list { margin: 0 0 16px; padding: 0 0 0 24px; font-size: 16px; } }

.category-search-item { margin: 0 12px 6px 0; }

@media screen and (min-width: 769px) { .category-search-item { margin: 0 20px 4px 0; } }

.category-search-item__link { background: rgba(62, 176, 255, 0.2); border-radius: 3px; color: #3eb0ff; display: block; font-size: 10px; line-height: 16px; padding: 0 6px; }

@media screen and (min-width: 769px) { .category-search-item__link { border-radius: 6px; font-size: 15px; line-height: 20px; padding: 4px 8px; } }

main { display: block; }

body { min-width: 320px; }

@media screen and (min-width: 769px) { body { min-width: 1060px; } }

.page-key { padding-bottom: 53px; position: relative; }

@media screen and (min-width: 769px) { .page-key { padding-bottom: 30px; } }

.page-key__kv--pc { display: none; }

@media screen and (min-width: 769px) { .page-key__kv--pc { display: block; content: ""; position: absolute; top: -190px; width: 100%; padding-bottom: 100%; background-repeat: no-repeat; background-size: cover; display: block; padding-bottom: 360px; background-position: center bottom 10px; z-index: -1; } }

.page-key__kv--sp { display: block; content: ""; position: absolute; top: -66px; width: 100%; padding-bottom: 100%; background-repeat: no-repeat; background-size: cover; display: block; padding-bottom: 161px; background-position: center bottom 10px; z-index: -1; }

@media screen and (min-width: 769px) { .page-key__kv--sp { display: none; } }

.page-key__content { position: relative; width: 100%; width: 100%; padding: 8px 12px 0 12px; }

@media screen and (min-width: 769px) { .page-key__content { max-width: 980px; margin: 0 auto; padding: 8px 12px 0 30px; } }

.breadcrumb { font-size: 10px; color: #3eb0ff; margin-bottom: 14px; }

@media screen and (min-width: 769px) { .breadcrumb { font-size: 12px; margin-bottom: 12px; } }

.breadcrumb__list { display: flex; margin-bottom: 18px; overflow-x: auto; white-space: nowrap; }

@media screen and (min-width: 769px) { .breadcrumb__list { margin-bottom: 20px; } }

.breadcrumb__item:not(:last-of-type):after { content: ">"; padding-left: 6px; padding-right: 10px; }

@media screen and (min-width: 769px) { .breadcrumb__item:not(:last-of-type):after { padding-left: 16px; padding-right: 16px; } }

.page-ttl { display: flex; align-items: center; }

.page-ttl__en { display: block; font-size: 14px; color: #3eb0ff; }

.page-ttl__en img { height: 12px; max-height: none; }

@media screen and (min-width: 769px) { .page-ttl__en img { width: auto; height: auto; } }

@media screen and (min-width: 769px) { .page-ttl__en { width: auto; height: auto; } }

.page-ttl__ja { color: #333; font-size: 13px; line-height: 15px; padding-left: 23px; margin-left: 11px; display: inline-block; background-repeat: no-repeat; background-position: left center; background-size: contain; }

@media screen and (min-width: 769px) { .page-ttl__ja { font-size: 23px; line-height: 30px; color: #555; padding-left: 45px; margin-left: 25px; } }

.page-ttl__img { display: block; }

.content { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .content { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .content { max-width: 920px; margin-top: 130px; } }

.content__ttl { font-size: 15px; border-bottom: 3px solid #3eb0ff; padding: 13px 0 9px; }

@media screen and (min-width: 769px) { .content__ttl { font-size: 31px; border-bottom: 4px solid #3eb0ff; padding: 13px 0 12px; margin-bottom: 42px; } }

.content__ttl--g { color: #555; text-align: center; font-size: 16px; font-weight: bold; margin-bottom: 20px; }

@media screen and (min-width: 769px) { .content__ttl--g { font-size: 20px; margin-bottom: 30px; } }

.content__ttl-icon { padding-left: 25px; display: inline-block; background-repeat: no-repeat; background-position: left center; background-size: 16px; }

@media screen and (min-width: 769px) { .content__ttl-icon { background-size: 33px; padding-left: 60px; margin-left: 12px; } }

.content__inner { width: 100%; }

@media screen and (min-width: 769px) { .content__inner { width: 770px; margin: 0 auto; } }

.content__form { width: 100%; }

@media screen and (min-width: 769px) { .content__form { width: 760px; } }

.text-area { line-height: 23px; letter-spacing: 0.05em; }

@media screen and (min-width: 769px) { .text-area { font-size: 16px; letter-spacing: 0em; line-height: 32px; } }

.text-area--center { text-align: center; }

.category-search--page { margin: 68px 0 50px; }

@media screen and (min-width: 769px) { .category-search--page { margin: 100px 0 55px; } }

/************************
------- form -------
************************/
select::-ms-expand { display: none; }

.step1 { margin-bottom: 50px; }

@media screen and (min-width: 769px) { .step1 { margin-bottom: 130px; } }

.step1__txt { margin-bottom: 30px; }

@media screen and (min-width: 769px) { .step1__txt { margin-bottom: 42px; } }

.step1__txt--l { font-size: 15px; color: #edad00; }

@media screen and (min-width: 769px) { .step1__txt--l { font-size: 20px; } }

.step1 .step { margin-bottom: 18px; }

.step2 { margin-bottom: 100px; }

.step2__txt { margin-bottom: 30px; }

@media screen and (min-width: 769px) { .step2__txt { margin-bottom: 40px; } }

@media screen and (min-width: 769px) { .step2 .content { margin-top: 83px; } }

.step2 .step { margin-bottom: 18px; }

.step3 { color: #555; margin-bottom: 100px; }

.step3__img { width: 100%; max-width: 420px; margin: 0 auto; }

.step3__txt { margin-bottom: 30px; text-align: center; }

@media screen and (min-width: 769px) { .step3__txt { letter-spacing: 0; margin-bottom: 50px; } }

@media screen and (min-width: 769px) { .step3 .content { margin-top: 83px; } }

.step3 .step { margin-bottom: 32px; }

@media screen and (min-width: 769px) { .step3 .step { margin-bottom: 60px; } }

.step__img--pc { display: none; }

@media screen and (min-width: 769px) { .step__img--pc { display: block; } }

.step__img--sp { text-align: center; }

@media screen and (min-width: 769px) { .step__img--sp { display: none; } }

.step3-img { width: 100%; max-width: 420px; margin: 0 auto; }

/************************ ------- form ------- */
.contact-form { color: #555; }

@media screen and (min-width: 769px) { .contact-form input, .contact-form textarea { font-size: 14px; } }

.contact-form input::placeholder, .contact-form textarea::placeholder { color: #afafaf; }

.contact-form select { color: #666; }

@media screen and (min-width: 769px) { .contact-form select { font-size: 14px; } }

.contact-form__table { border: 1px solid #3eb0ff; border-radius: 3px; }

@media screen and (min-width: 769px) { .contact-form__table { border: none; border-radius: 0; } }

.contact-form__tr { width: 100%; display: flex; align-items: stretch; flex-wrap: wrap; }

.contact-form__th { justify-content: flex-start; width: 100%; padding: 0.5rem 1rem; background-color: #d9f0ff; text-align: left; display: flex; margin: 0; }

@media screen and (min-width: 769px) { .contact-form__th { width: 175px; padding: 15px 9px 11px 18px; justify-content: space-between; } }

.contact-form__td { display: flex; flex-wrap: wrap; width: 100%; align-items: center; padding: 11px 14px 11px 10px; }

@media screen and (min-width: 769px) { .contact-form__td { width: calc(100% - 175px); position: relative; padding: 11px 16px 3px 23px; } }

.contact-form__txt { display: inline-block; font-size: 10px; margin-left: 10px; margin-top: 2px; }

@media screen and (min-width: 769px) { .contact-form__txt { font-size: 12px; margin-top: 0; } }

.contact-form__policy { margin-top: 24px; }

@media screen and (min-width: 769px) { .contact-form__policy { margin-top: 18px; } }

.contact-form__submit { font-size: 14px; margin-top: 35px; text-align: center; }

@media screen and (min-width: 769px) { .contact-form__submit { margin-top: 60px; } }

.contact-form__submit .submit-txt { font-weight: bold; margin-bottom: 5px; }

@media screen and (min-width: 769px) { .contact-form__submit .submit-txt { font-size: 16px; } }

.contact-form__submit input { max-width: 270px; }

@media screen and (min-width: 769px) { .contact-form__submit input { max-width: 450px; } }

.contact-form__submit input:hover { opacity: 0.8; }

.contact-form__submit--step2 { display: flex; width: 100%; max-width: 606px; margin: 10px auto 30px; justify-content: space-between; }

@media screen and (min-width: 769px) { .contact-form__submit--step2 { margin: 80px auto 0; } }

.contact-form__submit--step2 input { width: 48%; max-width: 266px; height: 100%; }

.contact-form__submit--step3 { display: flex; width: 100%; max-width: 606px; margin: 10px auto 30px; justify-content: space-between; }

@media screen and (min-width: 769px) { .contact-form__submit--step3 { margin: 70px auto 0; } }

.contact-form__submit--step3 input { width: 48%; max-width: 266px; height: 100%; }

@media screen and (min-width: 769px) { .contact-form .td-first { padding-top: 12px; } }

.contact-form .form-cb-list { margin-bottom: -10px; }

@media screen and (min-width: 769px) { .contact-form .form-cb-list { margin-bottom: -20px; } }

.contact-form .cb-item { flex: none; margin: 0 0 10px; width: 50%; }

@media screen and (min-width: 769px) { .contact-form .cb-item { margin: 0 0 20px; width: 25%; } }

.contact-form .select-input { background-position: right 8px center; background-size: 16px auto; }

@media screen and (min-width: 769px) { .contact-form .select-input { width: 133px; background-position: right 10px center; background-size: 24px auto; } }

.contact-form .select-input--y { width: 90px; }

@media screen and (min-width: 769px) { .contact-form .select-input--y { width: 133px; } }

.contact-form .select-input--m { width: 70px; }

@media screen and (min-width: 769px) { .contact-form .select-input--m { width: 86px; } }

.contact-form .select-input--d { width: 70px; }

@media screen and (min-width: 769px) { .contact-form .select-input--d { width: 86px; } }

.contact-form .select-input__txt { display: block; width: auto; margin-left: 3px; margin-right: 4px; }

@media screen and (min-width: 769px) { .contact-form .select-input__txt { margin-left: 5px; margin-right: 20px; } }

.select-input { background-position: right 8px center; background-size: 16px auto; }

@media screen and (min-width: 769px) { .select-input { width: 133px; background-position: right 10px center; background-size: 24px auto; } }

.select-input--y { width: 90px; }

@media screen and (min-width: 769px) { .select-input--y { width: 133px; } }

.select-input--m { width: 60px; }

@media screen and (min-width: 769px) { .select-input--m { width: 86px; } }

.select-input--d { width: 60px; }

@media screen and (min-width: 769px) { .select-input--d { width: 86px; } }

.select-input__txt { display: block; width: auto; margin-left: 3px; margin-right: 4px; }

@media screen and (min-width: 769px) { .select-input__txt { margin-left: 5px; margin-right: 20px; } }

.select-box { width: 100%; }

.txt-input { display: block; padding: 10px; width: 100%; }

@media screen and (min-width: 769px) { .txt-input { width: 286px; height: 35px; padding: 0 10px; } }

@media screen and (min-width: 769px) { .txt-input--l { width: 430px; } }

.txt-input-row { display: block; padding: 10px; width: 100%; resize: vertical; }

@media screen and (min-width: 769px) { .txt-input-row { width: 545px; padding: 17px 16px 0; } }

.select-input { background: #fff url(../img/icon_arrow_select_bl.png) no-repeat right 10px center; background-size: 24px auto; height: 35px; padding: 0 10px; width: 180px; }

@media screen and (min-width: 769px) { .select-input { font-size: 14px; width: 153px; } }

.select-input__txt { display: block; width: auto; margin-left: 5px; margin-right: 20px; }

@media screen and (min-width: 769px) { .select-input__txt { margin-left: 5px; margin-right: 20px; } }

.form-cb-list { width: 100%; display: flex; flex-wrap: wrap; }

.form-cb-list--4 .cb-item { display: block; width: 50%; }

@media screen and (min-width: 769px) { .form-cb-list--4 .cb-item { width: 25%; } }

.form-cb-list--3 .cb-item { display: block; width: 40%; }

@media screen and (min-width: 769px) { .form-cb-list--3 .cb-item { width: calc(100% / 3); } }

.cb { display: inline-block; }

.cb__input { display: none; }

.cb__input:checked + .cb__txt::after { content: ""; display: block; position: absolute; top: 0; left: 5px; width: 10px; height: 16px; transform: rotate(40deg); border-bottom: 3px solid #009a9a; border-right: 3px solid #009a9a; }

.cb__txt { display: inline-block; line-height: 20px; padding-left: 25px; position: relative; }

.cb__txt::before { background-color: #fff; border: 1px solid #ccc; border-radius: 3px; content: ""; display: inline-block; height: 20px; left: 0; position: absolute; top: 0px; width: 20px; }

.form-label { display: inline-block; font-size: 14px; color: #3eb0ff; font-weight: bold; text-align: left; margin-right: 18px; }

@media screen and (min-width: 769px) { .form-label { margin-right: auto; font-size: 16px; } }

.form-hissu { display: flex; justify-content: center; background-color: #f475a4; font-size: 11px; color: #fff; width: 36px; height: 1.6em; line-height: 1.6em; text-align: center; margin-top: 3px; font-size: 10px; border-radius: 4px; }

@media screen and (min-width: 769px) { .form-hissu { margin-left: auto; } }

.count-area::placeholder { vertical-align: top; }

.count-txt { width: 100%; margin-top: 5px; }

.form-policy__ttl { font-size: 14px; color: #3eb0ff; font-weight: normal; margin-bottom: 3px; }

@media screen and (min-width: 769px) { .form-policy__ttl { font-size: 14px; margin-bottom: 9px; } }

.form-policy__body { width: 100%; height: 88px; font-size: 10px; overflow-y: scroll; padding: 0 1rem; border: 1px solid #aaa; border-radius: 4px; white-space: pre-wrap; background-color: #fff; text-align: left; }

@media screen and (min-width: 769px) { .form-policy__body { height: 10em; font-size: 10px; padding: 0 1rem; } }

.error-msg { display: none; }

.error-inp .error-msg { display: block; width: 100%; height: 10px; font-size: 10px; line-height: 10px; color: #fd4c8e; }

.error-inp input[type="text"], .error-inp input[type="email"], .error-inp input[type="url"], .error-inp input[type="password"], .error-inp input[type="search"], .error-inp input[type="number"], .error-inp input[type="tel"], .error-inp input[type="range"], .error-inp input[type="date"], .error-inp input[type="month"], .error-inp input[type="week"], .error-inp input[type="time"], .error-inp input[type="datetime"], .error-inp input[type="datetime-local"], .error-inp input[type="color"], .error-inp textarea, .error-inp select { background-color: #fdeaf0; border: 1px solid #fd4c8e; }

.error-inp .cb__txt::before { background-color: #fdeaf0; border: 1px solid #fd4c8e; }

/************************ ------- search ------- */
.search { margin-bottom: 50px; }

@media screen and (min-width: 769px) { .search { margin-top: 85px; margin-bottom: 0; } }

.search__list { display: flex; align-items: flex-end; border-bottom: 1px dotted #c5c5c5; }

.search__item { align-items: baseline; background: #c5c5c5; border-radius: 6px 6px 0 0; color: #fff; cursor: pointer; display: flex; flex: none; justify-content: center; line-height: 30px; margin: 0 2px 0 0; width: 96px; }

@media screen and (min-width: 769px) { .search__item { line-height: 60px; margin: 0 4px 0 0; width: 190px; } }

.search__item_active { background: #3eb0ff; padding: 5px 0 0; }

@media screen and (min-width: 769px) { .search__item_active { padding: 10px 0 0; } }

.search__item-fs-lg { font-size: 14px; line-height: inherit; }

@media screen and (min-width: 769px) { .search__item-fs-lg { font-size: 28px; } }

.search__item-fs-md { font-size: 10px; line-height: inherit; }

@media screen and (min-width: 769px) { .search__item-fs-md { font-size: 20px; } }

.search__contents { display: none; }

.search__contents_active { display: block; }

.search__op-bt { text-align: center; padding-top: 10px; padding-bottom: 5px; border-bottom: 1px dotted #c5c5c5; cursor: pointer; font-size: 0; margin-bottom: 10px; }

@media screen and (min-width: 769px) { .search__op-bt { padding-top: 20px; padding-bottom: 4px; margin-bottom: 25px; } }

.search__op-bt img { width: 25px; height: auto; }

@media screen and (min-width: 769px) { .search__op-bt img { width: 43px; height: auto; } }

@media screen and (min-width: 769px) { .search-form { font-size: 16px; } }

.search-form__group { display: flex; padding: 5px 0; }

@media screen and (min-width: 769px) { .search-form__group { padding: 15px 0; } }

.search-form__group:not(:last-child) { border-bottom: 1px dotted #c5c5c5; }

.search-form__title { flex: none; color: #3eb0ff; line-height: 20px; padding: 0 0.5em; width: 7em; }

@media screen and (min-width: 769px) { .search-form__title { width: 8em; } }

.search-form__title_type_select { line-height: 35px; }

.search-form__item { flex: 1; }

.search-form__box { align-items: flex-start; display: flex; }

.search-form__box:not(:first-of-type) { border-top: 1px dotted #c5c5c5; margin: 5px 0 0; padding: 5px 0 0; }

@media screen and (min-width: 769px) { .search-form__box:not(:first-of-type) { margin: 15px 0 0; padding: 15px 0 0; } }

.search-form__box_type_select { border-top: none !important; }

.search-form__box_type_select:not(:first-of-type) { margin: 5px 0 0; padding: 0; }

@media screen and (min-width: 769px) { .search-form__box_type_select:not(:first-of-type) { margin: 7.5px 0 0; } }

.search-form__pref { display: flex; flex: none; margin: 0 1em 0 0; max-width: 4em; }

@media screen and (min-width: 769px) { .search-form__pref { font-size: 14px; line-height: 24px; max-width: 12em; } }

.search-form__cities { align-items: center; display: flex; flex: 1; flex-wrap: wrap; }

.search-form__city-wrap { display: flex; flex-wrap: wrap; }

@media screen and (min-width: 769px) { .search-form__city { font-size: 14px; } }

.search-form__city:last-of-type { margin-right: 0.5em; }

.search-form__city:not(:last-of-type)::after { content: ","; margin-right: 0.25em; }

.search-form__button { cursor: pointer; height: 12px; }

@media screen and (min-width: 769px) { .search-form__button_type_add { height: 24px; } }

.search-form__button_type_remove { margin: 0 0 0 auto; }

@media screen and (min-width: 769px) { .search-form__button_type_remove { height: 16px; } }

.search-form__img { display: block; }

.search-form__checkbox-list { display: flex; flex-wrap: wrap; margin: 0 0 -10px; }

@media screen and (min-width: 769px) { .search-form__checkbox-list { margin: 0 0 -20px; } }

.search-form__checkbox-item { flex: none; margin: 0 0 10px; width: 50%; }

@media screen and (min-width: 769px) { .search-form__checkbox-item { margin: 0 0 20px; width: 25%; } }

.search-form__submitbtn { display: block; text-align: center; }

.search-form__submit { display: none; height: 37px; margin: auto; margin-top: 15px; }

@media screen and (min-width: 769px) { .search-form__submit { height: 59px; } }

.search-form__submit:hover { opacity: 0.8; }

/************************ ------- registration button ------- */
.registration { display: flex; justify-content: center; }

.registration__button { display: block; text-align: center; }

.registration__img { display: block; width: 260px; }

@media screen and (min-width: 769px) { .registration__img { width: 520px; } }

/************************ ------- slider ------- */
.slide-list { width: 100%; padding: 0 12px 0 14px; font-size: 11px; padding: 0 25px; }

@media screen and (min-width: 769px) { .slide-list { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .slide-list { margin-top: 40px; width: 920px; } }

.slide-list__container { margin-top: 10px; }

@media screen and (min-width: 769px) { .slide-list__container { width: 100%; padding: 10px 0; } }

.slide-list__container--osusume { margin-bottom: 20px; }

.slide-list__container--osusume .slide-list-item { border: 1px solid #ffc5d8; }

.slide-list__container--rireki .slide-list-item { border: 1px solid #3eb0ff; }

.slide-list__ttl { /*color: #333; border-left: 3px solid #c3c3c3;*/ margin-bottom: 5px; }

@media screen and (min-width: 769px) { .slide-list__ttl { font-size: 24px; padding-left: 17px; margin-left: 6px; letter-spacing: 0.2em; line-height: 30px; } }

.slide-list__img--osusume { width: 187px; }

@media screen and (min-width: 769px) { .slide-list__img--osusume { width: 330px; } }

.slide-list__img--rireki { width: 77px; }

@media screen and (min-width: 769px) { .slide-list__img--rireki { width: 135px; } }

.slide-list__btn { margin-top: 10px; margin-top: 15px; text-align: center; }

@media screen and (min-width: 769px) { .slide-list__btn { margin-top: 15px; text-align: right; } }

.slide-list__osusume { background-color: #ffecf2; padding: 14px 4px 14px 4px; }

@media screen and (min-width: 769px) { .slide-list__osusume { margin: 0px 20px; padding: 25px 20px 30px 20px; } }

.slide-list__rireki { background-color: #e0f2fe; padding: 14px 4px 14px 4px; }

@media screen and (min-width: 769px) { .slide-list__rireki { margin: 0px 20px; padding: 20px 20px 15px 20px; } }

.slide-list-item { display: block; position: relative; background-color: #fff; width: calc(50% - 18px); max-width: 100%; border-radius: 6px; }

@media screen and (min-width: 769px) { .slide-list-item { width: 200px; } }

.slide-list-item__link { display: block; width: 100%; height: 100%; padding: 10px; }

.slide-list-item__caregory { display: block; color: #fff; width: 20px; height: 20px; text-align: center; line-height: 20px; border-radius: 3px; }

.slide-list-item__caregory--a { background-color: #3eb8b4; }

.slide-list-item__caregory--ha { background-color: #1bc3ed; }

.slide-list-item__tiku { color: #3eb0ff; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

@media screen and (min-width: 769px) { .slide-list-item__tiku { font-size: 15px; margin-top: 5px; } }

.slide-list-item__jikyu { color: #3eb0ff; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

@media screen and (min-width: 769px) { .slide-list-item__jikyu { font-size: 15px; } }

.slide-list-item__txt { color: #1776c2; text-decoration: underline; }

@media screen and (min-width: 769px) { .slide-list-item__txt { font-size: 14px; margin-top: 4px; } }

.slick-slide { margin: 0 5px; }

.slick-track { margin-left: 0; }

.slide-list__container--osusume .slick-prev:before { content: ""; height: 15px; background: url(../img/icon_arrow-left_pink.png) no-repeat center/cover; display: block; position: absolute; width: 10px; }

@media screen and (min-width: 769px) { .slide-list__container--osusume .slick-prev:before { height: 27px; width: 19px; left: -10px; } }

.slide-list__container--osusume .slick-next:before { content: ""; background: url(../img/icon_arrow-right_pink.png) no-repeat center/cover; display: block; height: 15px; position: absolute; width: 10px; }

@media screen and (min-width: 769px) { .slide-list__container--osusume .slick-next:before { height: 27px; width: 19px; right: -10px; } }

.slide-list__container--rireki .slick-prev:before { content: ""; background: url(../img/icon_arrow-left_blue.png) no-repeat center/cover; display: block; height: 15px; position: absolute; width: 10px; }

@media screen and (min-width: 769px) { .slide-list__container--rireki .slick-prev:before { height: 27px; width: 19px; left: -10px; } }

.slide-list__container--rireki .slick-next:before { content: ""; background: url(../img/icon_arrow-right_blue.png) no-repeat center/cover; display: block; height: 15px; position: absolute; width: 10px; }

@media screen and (min-width: 769px) { .slide-list__container--rireki .slick-next:before { height: 27px; width: 19px; right: -10px; } }

.slide-list__container--rireki .slick-prev { top: 60%; }

.slide-list__container--rireki .slick-next { top: 60%; }

.slick-prev:before, .slick-next:before { opacity: 1; }

.slick-prev { left: -20px; }

@media screen and (min-width: 769px) { .slick-prev { left: -40px; } }

.slick-next { right: -20px; }

@media screen and (min-width: 769px) { .slick-next { right: -40px; } }

.slick-prev, .slick-next { height: 15px; width: 10px; }

@media screen and (min-width: 769px) { .slick-prev, .slick-next { height: 27px; width: 19px; } }

/************************
------- modal -------
************************/
.modal { display: none; height: 100vh; left: 0; margin: auto; position: fixed; right: 0; top: 0; width: 100vw; z-index: 999; }

.modal__overlay { background-color: rgba(0, 0, 0, 0.5); height: 100vh; left: 0; position: absolute; top: 0; width: 100vw; z-index: 1; }

.modal__contents { background: #fff; left: 50%; max-height: 80%; max-width: 980px; overflow: auto; padding: 20px; position: absolute; transform: translate(-50%, -50%); top: 50%; width: 90%; z-index: 10; }

@media screen and (min-width: 769px) { .modal__contents { padding: 20px 30px; } }

.modal__buttons { align-items: center; display: flex; flex-direction: column; justify-content: space-between; }

@media screen and (min-width: 769px) { .modal__buttons { align-items: flex-end; flex-direction: row; margin: auto; max-width: 430px; } }

.modal__buttons_display_pc { display: none; }

@media screen and (min-width: 769px) { .modal__buttons_display_pc { display: flex; } }

@media screen and (max-width: 999px) { .modal__buttons_display_pc { display: none; } }

.modal__button { cursor: pointer; }

.modal__button_type_select { height: 34px; margin: 0 0 10px; }

@media screen and (min-width: 769px) { .modal__button_type_select { height: 44px; margin: 0; order: 1; } }

.modal__button_type_reset { height: 24px; }

@media screen and (min-width: 769px) { .modal__button_type_reset { height: 34px; order: 0; } }

.modal__button_type_close { position: absolute; right: 21px; top: 21px; width: 17px; }

@media screen and (min-width: 769px) { .modal__button_type_close { right: 31px; top: 21px; } }

.modal__img { display: block; }

.modal__title { font-size: 18px; font-weight: bold; line-height: 1; }

@media screen and (max-width: 999px) { .modal__title { max-width: 100%; padding-top: 15px; } }

@media screen and (min-width: 769px) { .modal__title { margin: -1em 0 0; max-width: 10em; } }

@media screen and (max-width: 768px) { .modal__title { padding-top: 0; } }

.modal__box { border: 1px solid #e0e0e0; border-top: 4px solid #3eb0ff; margin: 10px 0; }

@media screen and (min-width: 769px) { .modal__box { margin: 20px 0; } }

.modal__checkbox-list { display: flex; flex-wrap: wrap; padding: 10px; }

@media screen and (min-width: 769px) { .modal__checkbox-list { padding: 10px 20px; } }

.modal__checkbox-item { flex: none; margin: 10px 0 0; width: 50%; }

.modal__checkbox-num { color: #3eb0ff; }

.modal__checkbox-num::before { content: "（"; }

.modal__checkbox-num::after { content: "）"; }

.page-top { width: 100%; margin-left: auto; margin-right: auto; text-align: right; padding: 0 12px; display: block; margin-bottom: 47px; }

@media screen and (min-width: 769px) { .page-top { width: 980px; padding: 0; } }

.page-top img { width: 61px; }

/************************ ------- job ------- */
.jobsearch__page-ttl--en { height: 17px; }

@media screen and (min-width: 769px) { .jobsearch__page-ttl--en { height: auto; } }

.jobsearch__page-ttl--ja { background-image: url(../img/icon_ttl-search.png); }

.jobsearch__search { width: 100%; padding: 0 12px 0 14px; font-size: 11px; padding: 0; }

@media screen and (min-width: 769px) { .jobsearch__search { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .jobsearch__search { margin-top: 75px; margin-bottom: 68px; } }

.jobsearch__search__container { max-width: 920px; }

.jobsearch__search__menu { padding: 0; }

.jobsearch__ragistration { margin: 6px 0 60px; }

@media screen and (min-width: 769px) { .jobsearch__ragistration { margin: 43px 0 93px; } }

.jobsearch .content { margin-top: 0; }

.list-top { display: flex; padding-bottom: 15px; }

.list-top__pager { display: flex; width: 84px; justify-content: space-between; margin-left: auto; }

@media screen and (min-width: 769px) { .list-top__pager { width: 84px; } }

.pager-arrow { display: block; content: ""; width: 34px; height: 34px; background-size: contain; background-repeat: no-repeat; }

@media screen and (min-width: 769px) { .pager-arrow { width: 40px; height: 40px; } }

.pager-arrow--left { background-image: url(../img/icon_pager_prev.png); }

.pager-arrow--right { background-image: url(../img/icon_pager_next.png); }

.list-bottom { display: flex; padding-bottom: 15px; max-width: 400px; margin: 0 auto; margin-top: 25px; }

@media screen and (min-width: 769px) { .list-bottom { max-width: 100%; } }

.list-bottom__pager { display: flex; width: calc(100% - -20px); margin: 0 auto; justify-content: space-between; align-items: center; }

@media screen and (min-width: 769px) { .list-bottom__pager { margin-left: auto; margin-right: 0; justify-content: flex-end; width: auto; } }

.list-bottom__pager-arrow { width: 46px; height: 46px; }

@media screen and (min-width: 769px) { .list-bottom__pager-arrow { width: 38px; height: 38px; } }

.pager-link { font-size: 14px; color: #555; position: relative; display: block; padding: 0 0.5em; top: -5px; }

@media screen and (min-width: 769px) { .pager-link { font-size: 16px; display: block; padding: 0 10px; position: relative; top: -0.25em; color: #1766c2; } }

.pager-link.pager-link__enable { pointer-events: none; opacity: 0.2; color: #333333; }

.pager-link.pager-link__enable:after { display: none; }

.pager-link:after { content: ""; display: block; width: 100%; height: 1px; background-color: #555; }

@media screen and (min-width: 769px) { .pager-link:after { display: none; } }

.pager-link__prev { padding: 0 0 0 0; position: static; }

@media screen and (min-width: 769px) { .pager-link__prev { padding-left: 0; } }

.pager-link__prev:after { display: none; }

.pager-link__next { padding: 0 0 0 0; position: static; }

@media screen and (min-width: 769px) { .pager-link__next { padding-right: 0; } }

.pager-link__next:after { display: none; }

.job-count { font-size: 16px; color: #3eb0ff; display: inline-block; margin-right: 5px; }

@media screen and (min-width: 769px) { .job-count { font-size: 25px; } }

.job-count__txt--pc { display: none; }

@media screen and (min-width: 769px) { .job-count__txt--pc { display: inline-block; } }

.job-count__txt--sp { display: inline-block; }

@media screen and (min-width: 769px) { .job-count__txt--sp { display: none; } }

.list-box { color: #555; }

.list-item { border-top: 2px solid #3eb0ff; margin-bottom: 40px; }

@media screen and (min-width: 769px) { .list-item { border-top: 3px solid #3eb0ff; margin-bottom: 60px; } }

@media screen and (min-width: 769px) { .list-item:last-of-type { margin-bottom: 0; } }

.list-item__ttl { display: block; align-items: center; color: #3eb0ff; font-size: 13px; padding: 10px 10px 5px 10px; margin-bottom: 3px; line-height: 20px; font-weight: 600; }

@media screen and (min-width: 769px) { .list-item__ttl { font-size: 22px; padding: 16px 30px 7px 54px; margin-bottom: 9px; line-height: 30px; } }

.list-item__tag { display: flex; flex-wrap: wrap; padding: 0 10px; margin-right: -5px; margin-bottom: 5px; justify-content: flex-start; }

@media screen and (min-width: 769px) { .list-item__tag { padding-left: 52px; margin-bottom: 18px; justify-content: flex-start; } }

.list-item__details { background-color: #f4eee0; padding: 12px 10px 20px 10px; }

@media screen and (min-width: 769px) { .list-item__details { padding: 27px 50px 14px 48px; } }

.tag-item { display: block; padding: 3px; margin-right: 5px; margin-bottom: 5px; color: #1776c2; font-weight: bold; min-width: 63px; border: 1px solid #1776c2; text-align: center; border-radius: 3px; font-size: 12px; }

@media screen and (min-width: 769px) { .tag-item { padding: 0; min-width: 68px; font-size: 13px; padding: 0 7px; } }

.tag-item--new { color: #ff4985; background-color: #ffff00; border: none; }

.ttl-link { color: inherit; display: inline; }

.ttl-link:hover { text-decoration: underline; opacity: 1; }

.ttl-link:visited { color: #a0a0a0; }

.details-inner__top { display: flex; flex-wrap: nowrap; padding: 0; align-items: center; }

@media screen and (min-width: 769px) { .details-inner__top { padding: 0 6px; align-items: flex-start; } }

.details-inner__caregory { display: block; text-align: center; font-size: 12px; width: 22px; flex-shrink: 0; height: 22px; line-height: 22px; border-radius: 3px; }

@media screen and (min-width: 769px) { .details-inner__caregory { font-size: 14px; width: 23px; height: 23px; line-height: 23px; flex: none; } }

.details-inner__caregory--a { color: #fff; background-color: #3eb8b4; }

.details-inner__txt { display: flex; color: #3eb0ff; align-items: center; line-height: 1.35; }

@media screen and (min-width: 769px) { .details-inner__txt { align-items: flex-start; padding-left: 10px; } }

.details-inner__txt::before { display: block; content: ""; width: 1px; height: 15px; border-left: 1px solid #3eb0ff; margin-right: 8px; }

@media screen and (min-width: 769px) { .details-inner__txt::before { height: 23px; border-left: 2px solid #3eb0ff; margin-right: 50px; } }

.details-inner__txt--district { flex: 0 1 auto; font-size: 13px; margin-left: 10px; font-weight: 600; }

@media screen and (min-width: 769px) { .details-inner__txt--district { font-size: 18px; padding-left: 0; margin-left: 27px; font-weight: 600; } }

.details-inner__txt--pay { flex: 1 0 auto; max-width: 12em; font-size: 14px; margin-left: 10px; font-weight: 600; }

@media screen and (min-width: 769px) { .details-inner__txt--pay { max-width: none; font-size: 18px; padding-left: 0; margin-left: 50px; margin-top: 0; font-weight: 600; } }

.details-inner__content { display: flex; flex-wrap: wrap; margin-top: 10px; margin-bottom: 20px; }

.details-inner__btn { margin-top: 14px; width: 282px; margin: 14px auto 0; }

@media screen and (min-width: 769px) { .details-inner__btn { width: 516px; margin: 0 auto 0; justify-content: space-between; } }

.details-inner__btn--pc { display: none; }

@media screen and (min-width: 769px) { .details-inner__btn--pc { display: flex; } }

.details-inner__btn--sp { display: flex; justify-content: center; width: calc(100% - 10px); }

.details-inner__btn--sp a { padding: 0 5px; }

@media screen and (min-width: 769px) { .details-inner__btn--sp { display: none; } }

.details-inner__left { display: flex; width: 100%; flex-wrap: wrap; align-content: flex-start; }

@media screen and (min-width: 769px) { .details-inner__left { width: calc(100% - 290px); max-width: 530px; margin-left: 7px; } }

.details-list { display: flex; width: 100%; flex-wrap: wrap; align-content: flex-start; border-top: 1px dotted #c5c5c5; }

.details-list:last-of-type { border-bottom: 1px dotted #c5c5c5; }

.details-list__ttl { width: 77px; font-weight: bold; padding: 5px 0 5px 2px; font-size: 12px; }

@media screen and (min-width: 769px) { .details-list__ttl { width: 100px; padding: 6px 0 2px; font-size: 14px; } }

.details-list__data { width: calc(100% - 80px); padding: 5px 0; font-size: 12px; }

@media screen and (min-width: 769px) { .details-list__data { width: calc(100% - 100px); padding: 7px 0 7px 10px; font-size: 14px; } }

.job-id { width: 100%; font-weight: bold; color: #fc74a1; margin-top: 10px; }

@media screen and (min-width: 769px) { .job-id { order: 3; } }

.job-img { order: -1; width: 200px; margin: 0 auto 7px; }

@media screen and (min-width: 769px) { .job-img { width: 275px; margin: 0 0 0 auto; order: 0; } }

@media screen and (min-width: 769px) { .link-detail { width: 238px; } }

/************************ ------- job ------- */
.joblist__page-ttl--en { height: 19px; }

@media screen and (min-width: 769px) { .joblist__page-ttl--en { height: auto; } }

.joblist__page-ttl--ja { background-image: url(../img/icon_ttl-search.png); }

.joblist__search { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .joblist__search { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .joblist__search { margin-top: 75px; margin-bottom: 68px; } }

.joblist__ragistration { margin: 6px 0 60px; }

@media screen and (min-width: 769px) { .joblist__ragistration { margin: 43px 0 93px; } }

.joblist .content { margin-top: 0; }

/************************ ------- job ------- */
.job__page-ttl--en { height: 20px; }

@media screen and (min-width: 769px) { .job__page-ttl--en { height: auto; } }

.job__page-ttl--ja { background-image: url(../img/icon_recommend_ttl.png); }

@media screen and (min-width: 769px) { .job__page-ttl--ja { background-size: 30px; margin-left: 32px; padding-left: 51px; } }

.job__content { margin-top: 7px; }

@media screen and (min-width: 769px) { .job__content { margin-top: 106px; } }

.job__list-box { margin: 0; }

.job__list-item { margin: 0; }

.job__details-middle { display: flex; flex-wrap: wrap; }

.job__details-bottom { width: 100%; margin: 0 7px 0 3px; }

@media screen and (min-width: 769px) { .job__details-bottom { margin: 0 7px; } }

.job__job-id { order: 1; font-size: 10px; }

@media screen and (min-width: 769px) { .job__job-id { margin-bottom: 12px; order: 3; font-size: 14px; } }

.job__job-id span { font-weight: 600; padding-left: 2em; }

@media screen and (min-width: 769px) { .job__job-id span { padding-left: 2em; font-weight: 600; } }

.job__job-img { order: -1; }

@media screen and (min-width: 769px) { .job__job-img { order: 0; } }

.job__job-point { order: 1; margin-top: 20px; }

.job__ragistration { margin: 25px 0 40px; }

@media screen and (min-width: 769px) { .job__ragistration { margin: 101px 0 111px; } }

.job__details-list { border-bottom: none; border-top: 1px dotted #c5c5c5; }

.job__details-list:last-of-type { border-bottom: none; }

.job__entrybtn { text-align: center; width: 206px; }

@media screen and (min-width: 769px) { .job__entrybtn { width: 320px; } }

.job__list-item-details { padding: 12px 6px 28px 6px; }

@media screen and (min-width: 769px) { .job__list-item-details { padding: 27px 50px 50px 48px; } }

@media screen and (min-width: 769px) { .job .details-inner__left { min-height: 200px; } }

.job .details-inner__bottom { width: 100%; border-bottom: 1px dotted #c5c5c5; }

@media screen and (min-width: 769px) { .job .details-inner__bottom { margin-left: 7px; } }

.job-point { width: 100%; background-color: #fff; border-radius: 5px; display: flex; flex-wrap: wrap; align-items: stretch; margin-bottom: 14px; }

@media screen and (min-width: 769px) { .job-point { margin-top: 34px; margin-bottom: 0; } }

.job-point__ttl { background-color: #88cbff; width: 100%; height: 37px; padding: 3px 12px; border-radius: 3px 3px 0 0; }

@media screen and (min-width: 769px) { .job-point__ttl { display: flex; align-items: center; justify-content: center; border-radius: 5px 0 0 5px; padding: 16px 0 0; width: 168px; height: auto; min-height: 162px; } }

.job-point__ttl--pc { display: none; }

@media screen and (min-width: 769px) { .job-point__ttl--pc { display: block; } }

.job-point__ttl--sp { width: 100%; height: 100%; }

@media screen and (min-width: 769px) { .job-point__ttl--sp { display: none; } }

.job-point__txt { padding: 15px; width: 100%; font-size: 12px; }

.job-point__txt li { display: flex; justify-content: flex-start; align-items: flex-start; }

.job-point__txt li:before { content: "・"; display: block; flex: 0 0 1em; max-width: 1em; }

@media screen and (min-width: 769px) { .job-point__txt { flex: 1; padding: 24px 42px; font-size: 14px; } }

/************************ ------- job ------- */
.browsehistory__page-ttl--en { height: 19px; }

@media screen and (min-width: 769px) { .browsehistory__page-ttl--en { height: auto; } }

.browsehistory__page-ttl--ja { background-image: url(../img/icon_clock.png); }

.browsehistory__search { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .browsehistory__search { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .browsehistory__search { margin-top: 75px; margin-bottom: 68px; } }

.browsehistory__ragistration { margin: 6px 0 60px; }

@media screen and (min-width: 769px) { .browsehistory__ragistration { margin: 43px 0 70px; } }

/************************ ------- err ------- */
.job-err__page-ttl--en { height: 20px; }

@media screen and (min-width: 769px) { .job-err__page-ttl--en { height: auto; } }

.job-err__page-ttl--ja { background-image: url(../img/icon_clock.png); }

@media screen and (min-width: 769px) { .job-err__page-ttl--ja { margin-left: 34px; } }

.job-err__search { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .job-err__search { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .job-err__search { margin-top: 75px; margin-bottom: 176px; } }

.job-err__content { color: #555; text-align: center; }

@media screen and (min-width: 769px) { .job-err__content { font-size: 15px; margin-top: 128px; margin-bottom: 246px; } }

.job-err__registration { margin: 25px 0 40px; }

@media screen and (min-width: 769px) { .job-err__registration { margin: 142px 0; } }

/************************ ------- about ------- */
.aboutus__page-ttl--en { height: 20px; }

@media screen and (min-width: 769px) { .aboutus__page-ttl--en { height: auto; } }

.aboutus__page-ttl--ja { background-image: url(../img/icon_ttl-info.png); background-size: 15px; }

@media screen and (min-width: 769px) { .aboutus__page-ttl--ja { background-size: 30px; margin-left: 34px; } }

@media screen and (min-width: 769px) { .aboutus__content { margin-top: 93px; } }

.aboutus__content-ttlicon { background-image: url(../img/icon_ttl-info.png); }

/************************ ------- err ------- */
.err { color: #555; }

.err__page-ttl--en { height: 20px; }

@media screen and (min-width: 769px) { .err__page-ttl--en { height: auto; } }

.err__page-ttl--ja { background-image: url(../img/icon_clock.png); }

@media screen and (min-width: 769px) { .err__page-ttl--ja { margin-left: 34px; } }

.err__btn { margin-top: 30px; margin-bottom: 40px; }

@media screen and (min-width: 769px) { .err__btn { margin-top: 90px; margin-bottom: 100px; } }

.err__content { color: #555; text-align: center; }

@media screen and (min-width: 769px) { .err__content { font-size: 15px; margin-top: 128px; margin-bottom: 246px; } }

.flow { margin-bottom: 15px; margin-top: -30px; }

@media screen and (min-width: 769px) { .flow { overflow: hidden; } }

@media screen and (min-width: 769px) { .flow { margin-top: 95px; } }

@media screen and (min-width: 769px) { .flow__area { padding: 0px 30px 113px; } }

.flow__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .flow__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .flow__container { max-width: 920px; } }

.flow .text-area { padding: 27px 0 40px 0; }

.flow__aboutttl { border-bottom: 5px solid #51b8ff; font-size: 34px; line-height: 74px; padding-left: 70px; position: relative; }

.flow__aboutttl:after { content: ""; background: url(/img/icon_hand.png); background-position: center; background-size: cover; background-repeat: no-repeat; height: 31px; width: 34px; position: absolute; left: 12px; top: 19px; }

.flow__about { font-size: 11px; line-height: 21px; overflow: hidden; }

@media screen and (min-width: 769px) { .flow__about { font-size: 15px; line-height: 32px; padding-top: 35px; } }

.flow__unit { background-image: url(/img/bg_nagare_sp.png); background-repeat: no-repeat; background-position: bottom 20px center; background-size: cover; margin-top: 0px; padding-bottom: 35px; padding-top: 1px; position: relative; }

.flow__unit:before { background: url(/img/img_shape.png) no-repeat; content: ""; position: absolute; top: 0px; width: 100%; height: 29px; background-size: contain; }

@media screen and (min-width: 769px) { .flow__unit { background: none; padding-bottom: 104px; }
  .flow__unit:before { content: none; }
  .flow__unit:after { content: ""; background-image: url(/img/bg_nagare_pc.png); position: absolute; top: -70px; width: 100%; height: 641px; z-index: -1; background-position: center; background-repeat: no-repeat; } }

.flow__list { display: flex; flex-wrap: wrap; list-style: none; flex-direction: row; margin-top: 43px; }

@media screen and (min-width: 769px) { .flow__list { justify-content: center; margin-top: 117px; width: 920px; } }

.flow__item { align-items: center; display: flex; flex-direction: column; width: calc(50% - 10px); }

@media screen and (min-width: 769px) { .flow__item { width: calc(25% - 16px); margin-right: 10px; } }

.flow__item:nth-child(1), .flow__item:nth-child(2) { margin-bottom: 7px; }

@media screen and (min-width: 769px) { .flow__item:nth-child(1), .flow__item:nth-child(2) { margin-bottom: 0px; } }

.flow__item:nth-child(2n) { margin-left: 15px; }

@media screen and (min-width: 769px) { .flow__item:nth-child(2n) { margin-left: 0px; } }

.flow__item:last-of-type { margin-right: 0; }

.flow__circle { align-items: center; background-color: #ff4985; border-radius: 50%; display: flex; flex-direction: column; height: 141px; position: relative; text-align: center; width: 141px; }

@media screen and (min-width: 769px) { .flow__circle { height: 214px; width: 214px; } }

.flow__circle--01:after { content: ""; background: url(../img/icon_flow_01.png) no-repeat center/cover; display: block; position: absolute; height: 57px; left: 0; margin: auto; top: 70px; right: 0; width: 75px; }

@media screen and (min-width: 769px) { .flow__circle--01:after { height: 86px; top: 100px; width: 114px; } }

.flow__circle--02:after { content: ""; background: url(../img/icon_flow_02.png) no-repeat center/cover; display: block; position: absolute; height: 46px; left: 0; margin: auto; top: 82px; right: 0; width: 65px; }

@media screen and (min-width: 769px) { .flow__circle--02:after { height: 70px; top: 115px; width: 99px; } }

.flow__circle--03:after { content: ""; background: url(../img/icon_flow_03.png) no-repeat center/cover; display: block; position: absolute; height: 51px; left: 0; margin: auto; right: 0; top: 75px; width: 60px; }

@media screen and (min-width: 769px) { .flow__circle--03:after { height: 78px; top: 100px; width: 92px; } }

.flow__circle--04:after { content: ""; background: url(../img/icon_flow_04.png) no-repeat center/cover; display: block; position: absolute; height: 37px; left: 0; margin: auto; right: 0; top: 89px; width: 71px; }

@media screen and (min-width: 769px) { .flow__circle--04:after { height: 57px; top: 130px; width: 108px; } }

.flow__num { height: 17px; margin-top: 8px; width: 11px; }

@media screen and (min-width: 769px) { .flow__num { height: 26px; width: 20px; } }

.flow__itemttl { color: #fff; font-size: 17px; line-height: 21px; padding-top: 18px; }

@media screen and (min-width: 769px) { .flow__itemttl { font-size: 28px; line-height: 31px; } }

.flow__itemttl--two { padding-top: 13px; }

.flow__itemttl--span { letter-spacing: -0.13em; }

@media screen and (min-width: 769px) { .flow__itemttl--span { letter-spacing: -0.16em; } }

.flow__txt { font-size: 11px; line-height: 12px; padding-top: 7px; text-align: center; width: 100%; }

@media screen and (min-width: 769px) { .flow__txt { font-size: 14px; line-height: 21px; padding-right: 5px; padding-left: 5px; letter-spacing: -.05em; } }

.flow__link { color: #1879bc; text-align: center; text-decoration: underline; }

.flow__btn { margin-bottom: 40px; }

@media screen and (min-width: 769px) { .flow__btn { margin-bottom: 100px; } }

.content__flow { padding: 0; margin-top: 0; }

.content__ttl--flow { margin-bottom: 0px; letter-spacing: 0.1em; }

/************************
------- about -------
************************/
@media screen and (min-width: 769px) { #aboutbridgecareer .page-ttl__en { width: auto; } }

#aboutbridgecareer .page-ttl__ja { background-image: url(../img/icon_hand.png); }

#aboutbridgecareer .content__ttl-icon { background-image: url(../img/icon_hand.png); }

/************************ ------- for-recruitment-staff ------- */
.contact-business__page-ttl--en { height: 15px; }

@media screen and (min-width: 769px) { .contact-business__page-ttl--en { height: auto; } }

.contact-business__page-ttl--ja { background-image: url(../img/icon_jinzai.png); background-size: 11px; }

@media screen and (min-width: 769px) { .contact-business__page-ttl--ja { background-size: 26px; margin-left: 27px; line-height: 36px; } }

@media screen and (min-width: 769px) { .contact-business__content { margin-top: 93px; } }

.contact-business__content-ttlicon { background-image: url(../img/icon_jinzai.png); background-size: 26px; }

@media screen and (min-width: 769px) { .contact-business__content-ttlicon { padding-left: 50px; margin-left: 4px; } }

/************************ ------- entry ------- */
.entry__page-ttl--en { height: 20px; }

@media screen and (min-width: 769px) { .entry__page-ttl--en { height: auto; } }

.entry__page-ttl--ja { background-image: url(../img/icon_mail.png); background-size: 17px; }

@media screen and (min-width: 769px) { .entry__page-ttl--ja { background-size: 28px; margin-left: 14px; } }

@media screen and (min-width: 769px) { .entry__content { margin-top: 84px; } }

.entry-box { color: #555; }

.entry-box__ttl { padding-left: 20px; font-size: 12px; font-weight: bold; color: #3eb0ff; background-image: url(../img/icon_mail_bl.png); background-repeat: no-repeat; background-size: 12px; background-position: left center; margin-bottom: 7px; }

@media screen and (min-width: 769px) { .entry-box__ttl { font-size: 17px; background-size: 18px; } }

.entry-box__txt { background-color: #f6f6f6; padding: 13px 9px; margin-bottom: 14px; }

@media screen and (min-width: 769px) { .entry-box__txt { padding: 17px 30px; margin-bottom: 11px; } }

.faq { margin-top: 54px; }

@media screen and (min-width: 769px) { .faq { margin-top: 100px; } }

.faq__textarea { margin-bottom: 43px; }

@media screen and (min-width: 769px) { .faq__textarea { margin: 38px 0 53px; } }

.faq__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .faq__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .faq__container { max-width: 920px; } }

.faq__list { margin-bottom: 37px; }

@media screen and (min-width: 769px) { .faq__list { margin-bottom: 102px; padding: 0px 10px; } }

.faq__article { border-top: 1px dotted #b6b6b6; padding: 22px 0px 3px; }

@media screen and (min-width: 769px) { .faq__article:nth-child(1) { padding: 30px 0px; }
  .faq__article:nth-child(2) { padding: 39px 0px 28px; }
  .faq__article:nth-child(3) { padding: 49px 0px 6px; } }

.faq__article:last-child { border-bottom: 1px dotted #b6b6b6; }

.faq__img { width: 58px; }

@media screen and (min-width: 769px) { .faq__img { width: 94px; flex: 0 0 94px; } }

.faq__que { align-items: center; display: flex; margin-bottom: 17px; }

@media screen and (min-width: 769px) { .faq__que { margin-bottom: 30px; } }

.faq__txt { margin-left: 5px; line-height: 20px; }

@media screen and (min-width: 769px) { .faq__txt { line-height: 32px; } }

.faq__txt--que { font-size: 12px; font-weight: bold; }

@media screen and (min-width: 769px) { .faq__txt--que { font-size: 20px; } }

.faq__txt--ans { font-size: 11px; }

@media screen and (min-width: 769px) { .faq__txt--ans { font-size: 15px; } }

.faq__ans { align-items: flex-start; display: flex; margin-bottom: 10px; }

@media screen and (min-width: 769px) { .faq__ans { margin-left: 90px; } }

.faq__btn { margin-bottom: 40px; }

@media screen and (min-width: 769px) { .faq__btn { margin-bottom: 117px; } }

.content__faq { padding: 0; }

.content__ttl--faq { margin-bottom: 42px; letter-spacing: 0.1em; }

/************************
------- about -------
************************/
@media screen and (min-width: 769px) { #aboutbridgecareer .page-ttl__en { width: auto; } }

#aboutbridgecareer .page-ttl__ja { background-image: url(../img/icon_hand.png); }

#aboutbridgecareer .content__ttl-icon { background-image: url(../img/icon_hand.png); }

.company { margin-top: 44px; }

.company__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; padding: 0; }

@media screen and (min-width: 769px) { .company__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

.company__contents--first { margin: 9px 0 29px; }

@media screen and (min-width: 769px) { .company__contents--first { margin: 58px auto 0; } }

.company__contents--second { margin: 16px 12px 0; }

@media screen and (min-width: 769px) { .company__contents--second { margin: 35px 96px 90px; } }

.company__table { margin: 0 11px; }

@media screen and (min-width: 769px) { .company__table { margin: 0 103px; } }

.company__cat { color: #3eb0ff; font-size: 11px; line-height: 28px; width: 90px; }

@media screen and (min-width: 769px) { .company__cat { background: #ebf7ff; font-size: 16px; line-height: 52px; padding-left: 20px; width: 175px; } }

.company__item { font-size: 11px; line-height: 28px; }

@media screen and (min-width: 769px) { .company__item { font-size: 16px; line-height: 50px; padding-left: 30px; } }

@media screen and (min-width: 769px) { .company__tel:nth-child(1) { padding-right: 20px; } }

@media screen and (min-width: 769px) { .company__content { margin-top: 67px; } }

.company__txt { font-size: 11px; line-height: 21px; }

@media screen and (min-width: 769px) { .company__txt { font-size: 15px; line-height: 32px; } }

.company__list { margin-top: 20px; }

@media screen and (min-width: 769px) { .company__list { margin-top: 31px; } }

.company__listitem { font-size: 11px; line-height: 21px; }

@media screen and (min-width: 769px) { .company__listitem { font-size: 15px; line-height: 32px; } }

.company_map { height: 0; overflow: hidden; padding-bottom: 56.25%; position: relative; margin-top: 30px; }

.company_map iframe { position: absolute; left: 0; top: 0; height: 100%; width: 100%; }

.terms { margin-top: -8px; margin-bottom: 56px; }

.terms__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .terms__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

.terms__ttl { padding: 0; }

@media screen and (min-width: 769px) { .terms__ttl { margin-top: 108px; } }

.terms__textarea { font-size: 10px; line-height: 21px; letter-spacing: -0.08px; }

@media screen and (min-width: 769px) { .terms__textarea { font-size: 16px; line-height: 32px; } }

.terms__contents { margin-top: 14px; }

@media screen and (min-width: 769px) { .terms__center { margin: 39px 50px 0px 126px; } }

.terms__list { margin-top: 20px; }

@media screen and (min-width: 769px) { .terms__list { margin-top: 35px; } }

.terms__unit { margin-bottom: 22px; }

@media screen and (min-width: 769px) { .terms__unit { margin-bottom: 32px; } }

.terms__item { color: #3eb0ff; font-size: 11px; line-height: 21px; }

@media screen and (min-width: 769px) { .terms__item { font-size: 16px; line-height: 32px; } }

.terms__txt { font-size: 11px; line-height: 21px; padding-left: 10px; }

@media screen and (min-width: 769px) { .terms__txt { font-size: 16px; line-height: 32px; } }

.terms__nglist { padding-left: 10px; }

.terms__ngitem { letter-spacing: -0.05em; }

/************************ ------- feature ------- */
.feature__page-ttl--en img { height: 19px; }

@media screen and (min-width: 769px) { .feature__page-ttl--en img { height: auto; } }

.feature__search { width: 100%; padding: 0 12px 0 14px; font-size: 11px; padding: 0; }

@media screen and (min-width: 769px) { .feature__search { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .feature__search { margin-top: 75px; margin-bottom: 68px; } }

.feature__ragistration { margin: 6px 0 60px; }

@media screen and (min-width: 769px) { .feature__ragistration { margin: 43px 0 93px; } }

.feature .content { margin-top: 0; }

.reason { margin-bottom: 15px; margin-top: -30px; }

@media screen and (min-width: 769px) { .reason { overflow: hidden; } }

@media screen and (min-width: 769px) { .reason { margin-top: 95px; } }

@media screen and (min-width: 769px) { .reason__area { padding: 0px 30px 113px; } }

.reason__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .reason__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .reason__container { max-width: 920px; } }

.reason__ttl { margin-top: 5px; padding: 0; }

.reason .text-area { padding: 27px 10px 24px; }

@media screen and (min-width: 769px) { .reason .text-area { padding: 38px 10px 24px; } }

.reason__txtarea { padding-top: 41px; }

@media screen and (min-width: 769px) { .reason__txtarea { padding-top: 159px; } }

.reason__txt { color: #077fd2; font-size: 13px; line-height: 25px; }

@media screen and (min-width: 769px) { .reason__txt { font-size: 28px; line-height: 50px; } }

.reason__strength { color: #077fd2; font-size: 25px; line-height: 31px; }

@media screen and (min-width: 769px) { .reason__strength { font-size: 50px; line-height: 62px; } }

.reason__about { font-size: 11px; line-height: 16px; padding-top: 20px; }

.reason__unit { margin: 0 auto; max-width: 1385px; }

.reason__img { width: 100%; }

@media screen and (min-width: 769px) { .reason__img { margin-top: -70px; } }

.reason__img img { width: 100%; }

.reason__link { color: #1879bc; text-align: center; text-decoration: underline; }

.reason__btn { margin-top: 10px; margin-bottom: 86px; position: relative; }

@media screen and (min-width: 769px) { .reason__btn { margin: 60px auto 89px; } }

.reason__btnlink { position: absolute; top: -40px; }

.content__flow { padding: 0; margin-top: 7px; }

.content__ttl--reason { margin-bottom: 0px; letter-spacing: 0.1em; }

/************************
------- about -------
************************/
@media screen and (min-width: 769px) { #aboutbridgecareer .page-ttl__en { width: auto; } }

#aboutbridgecareer .page-ttl__ja { background-image: url(../img/icon_hand.png); }

#aboutbridgecareer .content__ttl-icon { background-image: url(../img/icon_hand.png); }

.thinking { margin-bottom: 15px; margin-top: -30px; }

@media screen and (min-width: 769px) { .thinking { overflow: hidden; } }

@media screen and (min-width: 769px) { .thinking { margin-top: 95px; } }

@media screen and (min-width: 769px) { .thinking__area { padding: 0px 30px 113px; } }

.thinking__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .thinking__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

@media screen and (min-width: 769px) { .thinking__container { max-width: 920px; } }

.thinking__ttl { margin-top: 5px; padding: 0; }

.thinking .text-area { padding: 27px 0px 30px; }

@media screen and (min-width: 769px) { .thinking .text-area { padding: 38px 10px 24px; } }

.thinking__txtarea { padding-top: 41px; }

@media screen and (min-width: 769px) { .thinking__txtarea { padding-top: 159px; } }

.thinking__txt { color: #077fd2; font-size: 13px; line-height: 25px; }

@media screen and (min-width: 769px) { .thinking__txt { font-size: 28px; line-height: 50px; } }

.thinking__strength { color: #077fd2; font-size: 25px; line-height: 31px; }

@media screen and (min-width: 769px) { .thinking__strength { font-size: 50px; line-height: 62px; } }

.thinking__about { font-size: 11px; line-height: 16px; padding-top: 20px; }

.thinking__unit { margin: 0 auto; max-width: 1385px; }

.thinking__img { width: 100%; }

@media screen and (min-width: 769px) { .thinking__img { margin-top: -70px; } }

.thinking__img img { width: 100%; }

.thinking__link { color: #1879bc; text-align: center; text-decoration: underline; }

.thinking__btn { margin-top: 30px; margin-bottom: 41px; position: relative; }

@media screen and (min-width: 769px) { .thinking__btn { margin: 80px auto 89px; } }

.content__thinking { padding: 0; margin-top: 7px; }

.content__ttl--thinking { margin-bottom: 0px; letter-spacing: 0.1em; }

/************************
------- about -------
************************/
@media screen and (min-width: 769px) { #aboutbridgecareer .page-ttl__en { width: auto; } }

#aboutbridgecareer .page-ttl__ja { background-image: url(../img/icon_hand.png); }

#aboutbridgecareer .content__ttl-icon { background-image: url(../img/icon_hand.png); }

.privacy { margin-top: -8px; margin-bottom: 56px; }

.privacy__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .privacy__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

.privacy__ttl { padding: 0; }

@media screen and (min-width: 769px) { .privacy__ttl { margin-top: 108px; } }

.privacy__textarea { font-size: 10px; line-height: 21px; letter-spacing: -0.08px; }

@media screen and (min-width: 769px) { .privacy__textarea { font-size: 16px; line-height: 32px; } }

.privacy__contents { margin-top: 14px; }

@media screen and (min-width: 769px) { .privacy__center { margin: 39px 50px 0px 126px; } }

.privacy__list { margin-top: 20px; }

@media screen and (min-width: 769px) { .privacy__list { margin-top: 35px; } }

.privacy__unit { margin-bottom: 22px; }

@media screen and (min-width: 769px) { .privacy__unit { margin-bottom: 32px; } }

.privacy__item { color: #3eb0ff; font-size: 11px; line-height: 21px; }

@media screen and (min-width: 769px) { .privacy__item { font-size: 16px; line-height: 32px; } }

.privacy__txt { font-size: 11px; line-height: 21px; padding-left: 21px; }

@media screen and (min-width: 769px) { .privacy__txt { font-size: 16px; line-height: 32px; } }

.privacy__mail-blue { color: #3a90d8; text-decoration: underline; }

/************************ ------- about ------- */
@media screen and (min-width: 769px) { #aboutus .page-ttl__en { width: auto; } }

#aboutus .page-ttl__en img { height: 13px; }

@media screen and (min-width: 769px) { #aboutus .page-ttl__en img { height: auto; } }

#aboutus .page-ttl__ja { background-image: url(../img/icon_ttl-info.png); }

#aboutus .content__ttl-icon { background-image: url(../img/icon_ttl-info.png); }

.copyright { margin-top: -8px; margin-bottom: 70px; }

@media screen and (min-width: 769px) { .copyright { margin-bottom: 140px; } }

.copyright__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .copyright__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

.copyright__ttl { padding: 0; }

@media screen and (min-width: 769px) { .copyright__ttl { margin-top: 108px; } }

.copyright__textarea { font-size: 10px; line-height: 21px; letter-spacing: -0.08px; }

@media screen and (min-width: 769px) { .copyright__textarea { font-size: 16px; line-height: 32px; } }

.copyright__contents { margin-top: 14px; }

@media screen and (min-width: 769px) { .copyright__center { margin: 39px 50px 0px 126px; } }

.copyright__list { margin-top: 20px; }

@media screen and (min-width: 769px) { .copyright__list { margin-top: 35px; } }

.copyright__unit { margin-bottom: 22px; }

@media screen and (min-width: 769px) { .copyright__unit { margin-bottom: 32px; } }

.copyright__item { color: #3eb0ff; font-size: 11px; line-height: 21px; }

@media screen and (min-width: 769px) { .copyright__item { font-size: 16px; line-height: 32px; } }

.copyright__txt { font-size: 11px; line-height: 21px; padding-left: 21px; }

@media screen and (min-width: 769px) { .copyright__txt { font-size: 16px; line-height: 32px; } }

.sitemap { margin-top: -8px; margin-bottom: 100px; }

@media screen and (min-width: 769px) { .sitemap { margin-bottom: 200px; } }

.sitemap__container { width: 100%; padding: 0 12px 0 14px; font-size: 11px; }

@media screen and (min-width: 769px) { .sitemap__container { max-width: 980px; margin: 0 auto; padding: 0; font-size: 14px; } }

.sitemap__ttl { padding: 0; }

@media screen and (min-width: 769px) { .sitemap__ttl { margin-top: 108px; } }

.sitemap__contents { margin-top: 0px; }

@media screen and (min-width: 769px) { .sitemap__center { margin: 0px 50px 0px 125px; } }

@media screen and (min-width: 769px) { .sitemap__area { display: flex; } }

.sitemap__list { width: 100%; }

@media screen and (min-width: 769px) { .sitemap__list { width: 246px; } }

.sitemap__list:nth-child(2) { margin-left: 0px; }

@media screen and (min-width: 769px) { .sitemap__list:nth-child(2) { margin-left: 152px; } }

.sitemap__item { color: #3eb0ff; border-bottom: 1px dotted #ccc; font-size: 13px; font-weight: bold; line-height: 21px; padding: 11px 0 11px 36px; position: relative; }

@media screen and (min-width: 769px) { .sitemap__item:nth-child(1) { border-top: 1px dotted #ccc; } }

.sitemap__item:after { content: ""; background: url(../img/icon_arrow_bl.png) no-repeat center/cover; display: block; height: 11px; left: 14px; position: absolute; top: 16px; width: 6px; }

.sitemap__item-inner { color: #3eb0ff; font-size: 13px; line-height: 21px; padding: 6px 0px 2px 15px; }

.sitemap__link { font-size: 13px; line-height: 21px; }

.sitemap__link-inner { font-size: 13px; line-height: 21px; }

.active { display: block; }

@media screen and (min-width: 769px) { .SP_br { display: none; } }

.pc_br { display: none; }

@media screen and (min-width: 769px) { .pc_br { display: block; } }

@media screen and (min-width: 769px) { .sp-only { display: none; } }

.pc-only { display: none; }

@media screen and (min-width: 769px) { .pc-only { display: block; } }
