/************************
  ------- about -------
  ************************/

  .aboutus {
    &__page-ttl {
      &--en {
        height: 20px;
        @include mq {
          height: auto;
        }
      }
      &--ja {
        background-image: url(../img/icon_ttl-info.png);
        background-size: 15px;
        @include mq {
          background-size: 30px;
          margin-left: 34px;
        }
      }
    }
    &__content {
      @include mq {
        margin-top: 93px;
      }
    }
    &__content-ttlicon{
      background-image: url(../img/icon_ttl-info.png);
    }
  }