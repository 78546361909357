

.privacy {
    margin-top: -8px;
    margin-bottom: 56px;
    &__container {
        @include container
    }
    &__ttl {
        padding: 0;
        @include mq {
            margin-top: 108px;
        }
    }
    &__textarea {
        font-size: 10px;
        line-height: 21px;
        letter-spacing: -0.08px;
        @include mq {
            font-size: 16px;
            line-height: 32px;
        }
    }
    &__contents {
        margin-top: 14px;
    }
    &__center {
        @include mq {
            margin: 39px 50px 0px 126px;
        }
    }
    &__list {
        margin-top: 20px;
        @include mq {
            margin-top: 35px;
        }
    }
    &__unit {
        margin-bottom: 22px;
        @include mq {
            margin-bottom: 32px;
        }
    }
    &__item {
        color: #3eb0ff;
        font-size: 11px;
        line-height: 21px;
        @include mq {
            font-size: 16px;
            line-height: 32px;
        }
    }
    &__txt {
        font-size: 11px;
        line-height: 21px;
        padding-left: 21px;
        @include mq {
            font-size: 16px;
            line-height: 32px;
        }
    }
    &__mail-blue {
        color: #3a90d8;
        text-decoration: underline;
    }
}

/************************
  ------- about -------
  ************************/
  #aboutus {
    .page-ttl{
      &__en {
        @include mq {
          width: auto;
        }
        img{
            height: 13px;
            @include mq {
                height: auto;
              }
        }
      }
      &__ja{
        background-image: url(../img/icon_ttl-info.png);
      }
    }
    .content{
      &__ttl-icon{
        background-image: url(../img/icon_ttl-info.png);
      }
    }
}
