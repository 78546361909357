/************************
  ------- err -------
  ************************/

.job-err {
  &__page-ttl {
    &--en {
      height: 20px;
      @include mq {
        height: auto;
      }
    }
    &--ja {
      background-image: url(../img/icon_clock.png);
            @include mq{
        margin-left: 34px;
      }
    }
  }
  &__search {
    @include container;
    @include mq {
      margin-top: 75px;
      margin-bottom: 176px;
    }
  }
  &__content {
    color: #555;
    text-align: center;
    @include mq {
      font-size: 15px;
      margin-top: 128px;
      margin-bottom: 246px;
    }
  }
  &__registration {
    margin: 25px 0 40px;
    @include mq {
      margin: 142px 0;
    }
  }
}
