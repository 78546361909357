

.thinking {
    margin-bottom: 15px;
    margin-top: -30px;
    @include mq {
        overflow: hidden;
    }
    @include mq {
        margin-top: 95px;
    }
    &__area {
        @include mq {
            padding: 0px 30px 113px;
        }
    }
    &__container {
        @include container;
        @include mq {
            max-width: 920px;
        }
    }
    &__ttl {
        margin-top: 5px;
        padding: 0;
    }
    .text-area {
        padding: 27px 0px 30px;
        @include mq {
            padding: 38px 10px 24px;
        }
    }
    &__txtarea {
        padding-top: 41px;
        @include mq {
            padding-top: 159px;
        }
    }
    &__txt {
        color: #077fd2;
        font-size: 13px;
        line-height: 25px;
        @include mq {
            font-size: 28px;
            line-height: 50px;
        }
    }
    &__strength {
        color: #077fd2;
        font-size: 25px;
        line-height: 31px;
        @include mq {
            font-size: 50px;
            line-height: 62px;
        }
    }
    &__about {
        font-size: 11px;
        line-height: 16px;
        padding-top: 20px;
    }
    &__unit {
        margin: 0 auto;
        max-width: 1385px;
    }
    &__img{
        width: 100%;
        @include mq{
            margin-top: -70px;
        }
        img{
            width: 100%;
        }
    }
    &__link {
        color: #1879bc;
        text-align: center;
        text-decoration: underline;
    }
    &__btn {
        margin-top: 30px;
        margin-bottom: 41px;
        position: relative;
        @include mq {
            margin: 80px auto 89px;
        }
    }
}



.content {
    &__thinking {
        padding: 0;
        margin-top: 7px;
    }
    &__ttl {
        &--thinking {
            margin-bottom: 0px;
            letter-spacing: 0.1em;
        }
    }
}

/************************
------- about -------
************************/
#aboutbridgecareer {
    .page-ttl{
        &__en {
            @include mq {
                width: auto;
            }
        }
        &__ja{
            background-image: url(../img/icon_hand.png);
        }
    }
    .content {
        &__ttl-icon{
            background-image: url(../img/icon_hand.png);
        }
    }
}
