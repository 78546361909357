

.sitemap {
    margin-top: -8px;
    margin-bottom: 100px;
    @include mq {
        margin-bottom: 200px;
    }
    &__container {
        @include container
    }
    &__ttl {
        padding: 0;
        @include mq {
            margin-top: 108px;
        }
    }
    &__contents {
        margin-top: 0px;
    }
    &__center {
        @include mq {
            margin: 0px 50px 0px 125px;
        }
    }
    &__area {
        @include mq {
            display: flex;
        }
    }
    &__list {
        width: 100%;
        @include mq {
            width: 246px;
        }
        &:nth-child(2) {
            margin-left: 0px;
            @include mq {
                margin-left: 152px;
            }
        }
    }
    &__item {
        color: #3eb0ff;
        border-bottom: 1px dotted #ccc;
        font-size: 13px;
        font-weight: bold;
        line-height: 21px;
        padding: 11px 0 11px 36px;
        position: relative;
        &:nth-child(1) {
            @include mq{
                border-top: 1px dotted #ccc;
            }
        }
        &:after {
            content: "";
            background: url(../img/icon_arrow_bl.png) no-repeat center / cover;
            display: block;
            height: 11px;
            left: 14px;
            position: absolute;
            top: 16px;
            width: 6px;
        }
    }
    &__item-inner {
        color: #3eb0ff;
        font-size: 13px;
        line-height: 21px;
        padding: 6px 0px 2px 15px;
    }
    &__link {
        font-size: 13px;
        line-height: 21px;
    }
    &__link-inner {
        font-size: 13px;
        line-height: 21px;
    }
}

