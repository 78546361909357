/************************
  ------- entry -------
  ************************/
.entry {
  &__page-ttl {
    &--en {
      height: 20px;
      @include mq {
        height: auto;
      }
    }
    &--ja {
      background-image: url(../img/icon_mail.png);
      background-size: 17px;
      @include mq {
        background-size: 28px;
        margin-left: 14px;
      }
    }
  }
  &__content {
    @include mq {
      margin-top: 84px;
    }
  }
}

.entry-box {
  color: #555;
  &__ttl {
    padding-left: 20px;
    font-size: 12px;
    font-weight: bold;
    color: #3eb0ff;
    background-image: url(../img/icon_mail_bl.png);
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: left center;
    margin-bottom: 7px;
    @include mq{
      font-size: 17px;
      background-size: 18px;
    }
  }
  &__txt {
    background-color: #f6f6f6;
    padding: 13px 9px;
    margin-bottom: 14px;
    @include mq{
      padding: 17px 30px;
      margin-bottom: 11px;
    }
  }
}

