/************************
  ------- feature -------
  ************************/

.feature {
  &__page-ttl {
    &--en {
      img {
        height: 19px;
        // max-height: none;
        @include mq {
          height: auto;
        }
      }
    }
  }
  &__search {
    @include container;
    padding: 0;
    @include mq {
      margin-top: 75px;
      margin-bottom: 68px;
    }
  }
  &__ragistration {
    margin: 6px 0 60px;
    @include mq {
      margin: 43px 0 93px;
    }
  }
  .content {
    margin-top: 0;
  }

}
