@import "~html5-reset";
@import "mixin";
@import "common";
@import "top";
@import "component";

//ページ別CSS
@import "01a";
@import "01c";
@import "01d";
@import "01f";
@import "01h";
@import "06g";
@import "08a";
@import "03d";
@import "05c";
@import "07a";
@import "03e";
@import "06b";
@import "06c";
@import "02b";
@import "03b";
@import "03c";
@import "06d";
@import "06e";
@import "06f";

.active{
    display: block;
  }


  .SP_br {
    @include mq {
      display: none;
    }
  }
  .pc_br {
    display: none;
    @include mq {
      display: block;
    }
  }
  .sp-only {
    @include mq {
      display: none;
    }
  }
  .pc-only {
    display: none;
    @include mq {
      display: block;
    }
  }
