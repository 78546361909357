main {
  display: block;
}

body {
  min-width: 320px;
  @include mq {
    min-width: 1060px;
  }
}

.page-key {
  padding-bottom: 53px;
  position: relative;
  @include mq {
    padding-bottom: 30px;
  }
  &__kv {
    &--pc {
      display: none;
      @include mq {
        display: block;
        content: "";
        position: absolute;
        top: -190px;
        width: 100%;
        padding-bottom: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        padding-bottom: 360px;
        background-position: center bottom 10px;
        z-index: -1;
      }
    }
    &--sp {
      display: block;
      content: "";
      position: absolute;
      top: -66px;
      width: 100%;
      padding-bottom: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      padding-bottom: 161px;
      background-position: center bottom 10px;
      z-index: -1;
      @include mq {
        display: none;
      }
    }
  }
  &__content {
    position: relative;
    width: 100%;
    width: 100%;
    padding: 8px 12px 0 12px;
    @include mq {
      max-width: 980px;
      margin: 0 auto;
      padding: 8px 12px 0 30px;
    }
  }
}

.breadcrumb {
  font-size: 10px;
  color: #3eb0ff;
  margin-bottom: 14px;
  @include mq {
    font-size: 12px;
    margin-bottom: 12px;
  }
  &__list {
    display: flex;
    margin-bottom: 18px;
    overflow-x: auto;
    white-space: nowrap;
    @include mq {
      margin-bottom: 20px;
    }
  }
  &__item {
    &:not(:last-of-type) {
      // padding-right: 10px;
      &:after {
        content: ">";
        padding-left: 6px;
        padding-right: 10px;
        @include mq {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }
}

.page-ttl {
  display: flex;
  align-items: center;
  &__en {
    display: block;
    font-size: 14px;
    color: #3eb0ff;
    img {
      height: 12px;
      max-height: none;
      @include mq {
        width: auto;
        height: auto;
      }
    }
    @include mq {
      width: auto;
      height: auto;
    }
  }
  &__ja {
    color: #333;
    font-size: 13px;
    line-height: 15px;
    padding-left: 23px;
    margin-left: 11px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    @include mq {
      font-size: 23px;
      line-height: 30px;
      color: #555;
      padding-left: 45px;
      margin-left: 25px;
    }
  }
  &__img {
    display: block;
  }
}

.content {
  @include container;
  @include mq() {
    max-width: 920px;
    margin-top: 130px;
  }
  &__ttl {
    font-size: 15px;
    border-bottom: 3px solid #3eb0ff;
    padding: 13px 0 9px;
    @include mq() {
      font-size: 31px;
      border-bottom: 4px solid #3eb0ff;
      padding: 13px 0 12px;
      margin-bottom: 42px;
    }
    &--g {
      color: #555;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
      @include mq {
        font-size: 20px;
        margin-bottom: 30px;
      }
    }
  }
  &__ttl-icon {
    padding-left: 25px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
    @include mq {
      background-size: 33px;
      padding-left: 60px;
      margin-left: 12px;
    }
  }
  &__inner {
    width: 100%;
    @include mq() {
      width: 770px;
      margin: 0 auto;
    }
  }
  &__form {
    width: 100%;
    @include mq() {
      width: 760px;
    }
  }
}

.text-area {
  line-height: 23px;
  letter-spacing: 0.05em;
  @include mq {
    font-size: 16px;
    letter-spacing: 0em;
    line-height: 32px;
  }
  &--center {
    text-align: center;
  }
}

.category-search--page {
  margin: 68px 0 50px;
  @include mq {
    margin: 100px 0 55px;
  }
}

/************************
------- form -------
************************/

select::-ms-expand {
  display: none;
}

.step1 {
  margin-bottom: 50px;
  @include mq {
    margin-bottom: 130px;
  }
  &__txt {
    margin-bottom: 30px;
    @include mq {
      margin-bottom: 42px;
    }
    &--l {
      font-size: 15px;
      color: #edad00;
      @include mq {
        font-size: 20px;
      }
    }
  }
  .step {
    margin-bottom: 18px;
  }
}
.step2 {
  margin-bottom: 100px;
  &__txt {
    margin-bottom: 30px;
    @include mq {
      margin-bottom: 40px;
    }
  }
  .content {
    @include mq {
      margin-top: 83px;
    }
  }
  .step {
    margin-bottom: 18px;
  }
}
.step3 {
  color: #555;
  margin-bottom: 100px;
  &__img {
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
  }
  &__txt {
    margin-bottom: 30px;
    text-align: center;
    @include mq {
      // font-size: 22px;
      letter-spacing: 0;
      margin-bottom: 50px;
    }
  }
  .content {
    @include mq {
      margin-top: 83px;
    }
  }
  .step {
    margin-bottom: 32px;
    @include mq {
      margin-bottom: 60px;
    }
  }
}
.step {
  &__img {
    &--pc {
      display: none;
      @include mq {
        display: block;
      }
    }
    &--sp {
      text-align: center;
      @include mq {
        display: none;
      }
    }
  }
}
.step3-img {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
}
/************************
  ------- form -------
  ************************/

.contact-form {
  color: #555;
  input,
  textarea {
    @include mq {
      font-size: 14px;
    }
    &::placeholder {
      color: #afafaf;
    }
  }
  select {
    color: #666;
    @include mq {
      font-size: 14px;
    }
  }
  &__table {
    border: 1px solid #3eb0ff;
    border-radius: 3px;
    @include mq {
      border: none;
      border-radius: 0;
    }
  }
  &__tr {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
  &__th {
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: #d9f0ff;
    text-align: left;
    display: flex;
    margin: 0;
    @include mq {
      width: 175px;
      padding: 15px 9px 11px 18px;
      justify-content: space-between;
      // align-self:flex-start;
    }
  }
  &__td {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    padding: 11px 14px 11px 10px;
    @include mq {
      width: calc(100% - 175px);
      position: relative;
      padding: 11px 16px 3px 23px;
    }
  }
  &__txt {
    display: inline-block;
    font-size: 10px;
    margin-left: 10px;
    margin-top: 2px;
    @include mq {
      font-size: 12px;
      margin-top: 0;
    }
  }
  &__policy {
    margin-top: 24px;
    @include mq {
      margin-top: 18px;
    }
  }
  &__submit {
    font-size: 14px;
    margin-top: 35px;
    text-align: center;
    @include mq {
      margin-top: 60px;
    }
    .submit-txt {
      font-weight: bold;
      margin-bottom: 5px;
      @include mq {
        font-size: 16px;
      }
    }
    input {
      max-width: 270px;
      @include mq {
        max-width: 450px;
      }
      &:hover{
        opacity: 0.8;
      }
    }
    &--step2 {
      display: flex;
      width: 100%;
      max-width: 606px;
      margin: 10px auto 30px;
      justify-content: space-between;
      @include mq {
        margin: 80px auto 0;
      }
      input {
        width: 48%;
        max-width: 266px;
        height: 100%;
      }
    }
    &--step3 {
      display: flex;
      width: 100%;
      max-width: 606px;
      margin: 10px auto 30px;
      justify-content: space-between;
      @include mq {
        margin: 70px auto 0;
      }
      input {
        width: 48%;
        max-width: 266px;
        height: 100%;
      }
    }
  }
  .td-first {
    @include mq {
      padding-top: 12px;
    }
  }
  .form-cb-list {
    margin-bottom: -10px;
    @include mq {
      margin-bottom: -20px;
    }
  }
  .cb-item {
    flex: none;
    margin: 0 0 10px;
    width: 50%;
    @include mq {
      margin: 0 0 20px;
      width: 25%;
    }
  }
  .select-input {
    background-position: right 8px center;
    background-size: 16px auto;
    @include mq {
      width: 133px;
      background-position: right 10px center;
      background-size: 24px auto;
    }
    &--y {
      width: 90px;
      @include mq {
        width: 133px;
      }
    }
    &--m {
      width: 70px;
      @include mq {
        width: 86px;
      }
    }
    &--d {
      width: 70px;
      @include mq {
        width: 86px;
      }
    }
    &__txt {
      display: block;
      width: auto;
      margin-left: 3px;
      margin-right: 4px;
      @include mq {
        margin-left: 5px;
        margin-right: 20px;
      }
    }
  }
}
  .select-input {
    background-position: right 8px center;
    background-size: 16px auto;
    @include mq {
      width: 133px;
      background-position: right 10px center;
      background-size: 24px auto;
    }
    &--y {
      width: 90px;
      @include mq {
        width: 133px;
      }
    }
    &--m {
      width: 60px;
      @include mq {
        width: 86px;
      }
    }
    &--d {
      width: 60px;
      @include mq {
        width: 86px;
      }
    }
    &__txt {
      display: block;
      width: auto;
      margin-left: 3px;
      margin-right: 4px;
      @include mq {
        margin-left: 5px;
        margin-right: 20px;
      }
    }
  }
.select-box {
  width: 100%;
}

.txt-input {
  display: block;
  padding: 10px;
  width: 100%;
  @include mq {
    width: 286px;
    height: 35px;
    padding: 0 10px;
  }
  &--l {
    @include mq {
      width: 430px;
    }
  }
}
.txt-input-row {
  display: block;
  padding: 10px;
  width: 100%;
  resize: vertical;
  @include mq {
    width: 545px;
    padding: 17px 16px 0;
  }
}

.select-input {
  background: #fff url(../img/icon_arrow_select_bl.png) no-repeat right 10px center;
  background-size: 24px auto;
  height: 35px;
  padding: 0 10px;
  width: 180px;
  @include mq {
    font-size: 14px;
    width: 153px;
  }
  &__txt {
    display: block;
    width: auto;
    margin-left: 5px;
    margin-right: 20px;
    @include mq {
      margin-left: 5px;
      margin-right: 20px;
    }
  }
}
.form-cb-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &--4 {
    .cb-item {
      display: block;
      width: 50%;
      @include mq {
        width: 25%;
      }
    }
  }
  &--3 {
    .cb-item {
      display: block;
      width: 40%;
      @include mq {
        width: calc(100% / 3);
      }
    }
  }
}
.cb {
  display: inline-block;
  &__input {
    display: none;
    &:checked + .cb__txt::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 5px;
      width: 10px;
      height: 16px;
      transform: rotate(40deg);
      border-bottom: 3px solid #009a9a;
      border-right: 3px solid #009a9a;
    }
  }
  &__txt {
    display: inline-block;
    line-height: 20px;
    padding-left: 25px;
    position: relative;
    &::before {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 3px;
      content: "";
      display: inline-block;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0px;
      width: 20px;
    }
  }
}

.form-label {
  display: inline-block;
  font-size: 14px;
  color: #3eb0ff;
  font-weight: bold;
  text-align: left;
  margin-right: 18px;
  @include mq {
    margin-right: auto;
    font-size: 16px;
  }
}
.form-hissu {
  display: flex;
  justify-content: center;
  background-color: #f475a4;
  font-size: 11px;
  color: #fff;
  width: 36px;
  height: 1.6em;
  line-height: 1.6em;
  text-align: center;
  margin-top: 3px;
  font-size: 10px;
  border-radius: 4px;
  @include mq {
    margin-left: auto;
  }
}

.count-area {
  &::placeholder {
    vertical-align: top;
  }
}
.count-txt {
  width: 100%;
  margin-top: 5px;
}
.form-policy {
  &__ttl {
    font-size: 14px;
    color: #3eb0ff;
    font-weight: normal;
    margin-bottom: 3px;
    @include mq {
      font-size: 14px;
      margin-bottom: 9px;
    }
  }
  &__body {
    width: 100%;
    height: 88px;
    font-size: 10px;
    overflow-y: scroll;
    padding: 0 1rem;
    border: 1px solid #aaa;
    border-radius: 4px;
    white-space: pre-wrap;
    background-color: #fff;
    text-align: left;
    @include mq {
      height: 10em;
      font-size: 10px;
      padding: 0 1rem;
    }
  }
}

.error-msg {
  display: none;
}
.error-inp {
  .error-msg {
    display: block;
    width: 100%;
    height: 10px;
    font-size: 10px;
    line-height: 10px;
    color: #fd4c8e;
  }
  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  input[type="search"],
  input[type="number"],
  input[type="tel"],
  input[type="range"],
  input[type="date"],
  input[type="month"],
  input[type="week"],
  input[type="time"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="color"],
  textarea,
  select {
    background-color: #fdeaf0;
    border: 1px solid #fd4c8e;
  }
  .cb__txt::before {
    background-color: #fdeaf0;
    border: 1px solid #fd4c8e;
  }
}

/************************
  ------- search -------
  ************************/

.search {
  margin-bottom: 50px;
  @include mq {
    margin-top: 85px;
    margin-bottom: 0;
  }
  &__list {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px dotted #c5c5c5;
  }
  &__item {
    align-items: baseline;
    background: #c5c5c5;
    border-radius: 6px 6px 0 0;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex: none;
    justify-content: center;
    line-height: 30px;
    margin: 0 2px 0 0;
    width: 96px;
    @include mq {
      line-height: 60px;
      margin: 0 4px 0 0;
      width: 190px;
    }
    &_active {
      background: #3eb0ff;
      padding: 5px 0 0;
      @include mq {
        padding: 10px 0 0;
      }
    }
  }
  &__item-fs-lg {
    font-size: 14px;
    line-height: inherit;
    @include mq {
      font-size: 28px;
    }
  }
  &__item-fs-md {
    font-size: 10px;
    line-height: inherit;
    @include mq {
      font-size: 20px;
    }
  }
  &__contents {
    display: none;
    &_active {
      display: block;
    }
  }
  &__op-bt {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px dotted #c5c5c5;
    cursor: pointer;
    font-size: 0;
    margin-bottom: 10px;
    @include mq {
      padding-top: 20px;
      padding-bottom: 4px;
      margin-bottom: 25px;
    }

    img {
      width: 25px;
      height: auto;
      @include mq {
        width: 43px;
        height: auto;
      }
    }
  }
}

.search-form {
  @include mq {
    font-size: 16px;
  }
  &__group {
    display: flex;
    padding: 5px 0;
    @include mq {
      padding: 15px 0;
    }
    &:not(:last-child) {
      border-bottom: 1px dotted #c5c5c5;
    }
  }
  &__title {
    flex: none;
    color: #3eb0ff;
    line-height: 20px;
    padding: 0 0.5em;
    width: 7em;
    @include mq {
      width: 8em;
    }
    &_type {
      &_select {
        line-height: 35px;
      }
    }
  }
  &__item {
    flex: 1;
  }
  &__box {
    align-items: flex-start;
    display: flex;
    &:not(:first-of-type) {
      border-top: 1px dotted #c5c5c5;
      margin: 5px 0 0;
      padding: 5px 0 0;
      @include mq {
        margin: 15px 0 0;
        padding: 15px 0 0;
      }
    }
    &_type {
      &_select {
        border-top: none !important;
        &:not(:first-of-type) {
          margin: 5px 0 0;
          padding: 0;
          @include mq {
            margin: 7.5px 0 0;
          }
        }
      }
    }
  }
  &__pref {
    display: flex;
    flex: none;
    margin: 0 1em 0 0;
    max-width: 4em;
    @include mq {
      font-size: 14px;
      line-height: 24px;
      max-width: 12em;
    }
  }
  &__cities {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }
  &__city-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  &__city {
    @include mq {
      font-size: 14px;
    }
    &:last-of-type {
      margin-right: 0.5em;
    }
    &:not(:last-of-type)::after {
      content: ",";
      margin-right: 0.25em;
    }
  }
  &__button {
    cursor: pointer;
    height: 12px;
    &_type {
      &_add {
        @include mq {
          height: 24px;
        }
      }
      &_remove {
        margin: 0 0 0 auto;
        @include mq {
          height: 16px;
        }
      }
    }
  }
  &__img {
    display: block;
  }
  &__checkbox-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -10px;
    @include mq {
      margin: 0 0 -20px;
    }
  }
  &__checkbox-item {
    flex: none;
    margin: 0 0 10px;
    width: 50%;
    @include mq {
      margin: 0 0 20px;
      width: 25%;
    }
  }
  &__submitbtn{
    display: block;
    text-align: center;
  }
  &__submit {
    display: none;
    height: 37px;
    margin: auto;
    margin-top:15px;
    @include mq {
      height: 59px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

/************************
  ------- registration button -------
  ************************/

.registration {
  display: flex;
  justify-content: center;
  &__button {
    display: block;
    text-align: center;
  }
  &__img {
    display: block;
    width: 260px;
    @include mq {
      width: 520px;
    }
  }
}

/************************
  ------- slider -------
  ************************/

.slide-list {
  @include container;
  padding: 0 25px;
  @include mq {
    margin-top: 40px;
    width: 920px;
  }
  &__container {
    margin-top: 10px;
    @include mq {
      width: 100%;
      padding: 10px 0;
    }
    &--osusume {
      margin-bottom: 20px;
      .slide-list-item {
        border: 1px solid #ffc5d8;
        // height: 210px;
        // overflow: hidden;
      }
    }
    &--rireki {
      .slide-list-item {
        border: 1px solid #3eb0ff;
      }
    }
  }
  &__ttl {
    /*color: #333;
      border-left: 3px solid #c3c3c3;*/
    margin-bottom: 5px;
    @include mq {
      font-size: 24px;
      padding-left: 17px;
      margin-left: 6px;
      letter-spacing: 0.2em;
      line-height: 30px;
    }
  }
  &__img {
    &--osusume {
      width: 187px;
      @include mq {
        width: 330px;
      }
    }
    &--rireki {
      width: 77px;
      @include mq {
        width: 135px;
      }
    }
  }
  &__btn {
    margin-top: 10px;
    margin-top: 15px;
    text-align: center;
    @include mq {
      margin-top: 15px;
      text-align: right;
    }
  }
  &__osusume {
    background-color: #ffecf2;
    padding: 14px 4px 14px 4px;
    @include mq {
      margin: 0px 20px;
      padding: 25px 20px 30px 20px;
    }
  }
  &__rireki {
    background-color: #e0f2fe;
    padding: 14px 4px 14px 4px;
    @include mq {
      margin: 0px 20px;
      padding: 20px 20px 15px 20px;
    }
  }
}

.slide-list-item {
  display: block;
  position: relative;
  background-color: #fff;
  width: calc(50% - 18px);
  max-width: 100%;
  border-radius: 6px;
  @include mq {
    width: 200px;
  }
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  &__caregory {
    display: block;
    color: #fff;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 3px;
    &--a {
      background-color: #3eb8b4;
    }
    &--ha {
      background-color: #1bc3ed;
    }
  }
  &__tiku {
    color: #3eb0ff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include mq {
      font-size: 15px;
      margin-top: 5px;
    }
  }
  &__jikyu {
    color: #3eb0ff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include mq {
      font-size: 15px;
    }
  }
  &__txt {
    color: #1776c2;
    text-decoration: underline;
    @include mq {
      font-size: 14px;
      margin-top: 4px;
    }
  }
}

// .slider {
//   display: none;
// }
// .slider.slick-initialized {
//   display: block;
// }
.slick-slide {
  margin: 0 5px;
}

.slick-track {
  margin-left: 0;
}

.slide-list__container--osusume .slick-prev:before {
  content: "";
  height: 15px;
  background: url(../img/icon_arrow-left_pink.png) no-repeat center / cover;
  display: block;
  position: absolute;
  width: 10px;
  @include mq {
    height: 27px;
    width: 19px;
    left: -10px;
  }
}

.slide-list__container--osusume .slick-next:before {
  content: "";
  background: url(../img/icon_arrow-right_pink.png) no-repeat center / cover;
  display: block;
  height: 15px;
  position: absolute;
  width: 10px;
  @include mq {
    height: 27px;
    width: 19px;
    right: -10px;
  }
}

.slide-list__container--rireki .slick-prev:before {
  content: "";
  background: url(../img/icon_arrow-left_blue.png) no-repeat center / cover;
  display: block;
  height: 15px;
  position: absolute;
  width: 10px;
  @include mq {
    height: 27px;
    width: 19px;
    left: -10px;
  }
}

.slide-list__container--rireki .slick-next:before {
  content: "";
  background: url(../img/icon_arrow-right_blue.png) no-repeat center / cover;
  display: block;
  height: 15px;
  position: absolute;
  width: 10px;
  @include mq {
    height: 27px;
    width: 19px;
    right: -10px;
  }
}

.slide-list__container--rireki .slick-prev {
  top: 60%;
}

.slide-list__container--rireki .slick-next {
  top: 60%;
}

.slick-prev:before,
.slick-next:before {
  opacity: 1;
}

.slick-prev {
  left: -20px;
  @include mq {
    left: -40px;
  }
}

.slick-next {
  right: -20px;
  @include mq {
    right: -40px;
  }
}

.slick-prev,
.slick-next {
  height: 15px;
  width: 10px;
  @include mq {
    height: 27px;
    width: 19px;
  }
}

/************************
------- modal -------
************************/

.modal {
  display: none;
  height: 100vh;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 999;
  &__overlay {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1;
  }
  &__contents {
    background: #fff;
    left: 50%;
    max-height: 80%;
    max-width: 980px;
    overflow: auto;
    padding: 20px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 90%;
    z-index: 10;
    @include mq {
      padding: 20px 30px;
    }
  }
  &__buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mq {
      align-items: flex-end;
      flex-direction: row;
      margin: auto;
      max-width: 430px;
    }
    &_display {
      &_pc {
        display: none;
        @include mq {
          display: flex;
        }
        @media screen and (max-width: 999px){
          display: none;
        }
      }
    }
  }
  &__button {
    cursor: pointer;
    &_type {
      &_select {
        height: 34px;
        margin: 0 0 10px;
        @include mq {
          height: 44px;
          margin: 0;
          order: 1;
        }
      }
      &_reset {
        height: 24px;
        @include mq {
          height: 34px;
          order: 0;
        }
      }
      &_close {
        position: absolute;
        right: 21px;
        top: 21px;
        width: 17px;
        @include mq {
          right: 31px;
          top: 21px;
        }
      }
    }
  }
  &__img {
    display: block;
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    
    @media screen and (max-width: 999px){
      max-width: 100%;
      padding-top:15px;
    }
    @include mq {
      margin: -1em 0 0;
      max-width: 10em;
    }
    @media screen and (max-width: 768px){
      padding-top:0;
    }
  }
  &__box {
    border: 1px solid #e0e0e0;
    border-top: 4px solid #3eb0ff;
    margin: 10px 0;
    @include mq {
      margin: 20px 0;
    }
  }
  &__checkbox-list {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    @include mq {
      padding: 10px 20px;
    }
  }
  &__checkbox-item {
    flex: none;
    margin: 10px 0 0;
    width: 50%;
  }
  &__checkbox-num {
    color: #3eb0ff;
    &::before {
      content: "（";
    }
    &::after {
      content: "）";
    }
  }
}

.page-top{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  padding:0 12px;
  display: block;
  margin-bottom: 47px;
  @include mq {
    width: 980px;
    padding:0;
  }
  img{
    width: 61px;
  }
}