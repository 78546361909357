

.flow {
    margin-bottom: 15px;
    margin-top: -30px;
    @include mq {
        overflow: hidden;
    }
    @include mq {
        margin-top: 95px;
    }
    &__area {
        @include mq {
            padding: 0px 30px 113px;
        }
    }
    &__container {
        @include container;
        @include mq {
            max-width: 920px;
        }
    }
    .text-area{
        padding: 27px 0 40px 0;
    }
    &__aboutttl {
        border-bottom: 5px solid #51b8ff;
        font-size: 34px;
        line-height: 74px;
        padding-left: 70px;
        position: relative;
        &:after {
            content: "";
            background: url(/img/icon_hand.png);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 31px;
            width: 34px;
            position: absolute;
            left: 12px;
            top: 19px;
        }
    }
    &__about {
        font-size: 11px;
        line-height: 21px;
        overflow: hidden;
        @include mq {
            font-size: 15px;
            line-height: 32px;
            padding-top: 35px;
        }
    }
    &__unit {
        background-image: url(/img/bg_nagare_sp.png);
        background-repeat: no-repeat;
        background-position: bottom 20px center;
        background-size: cover;
        margin-top: 0px;
        padding-bottom: 35px;
        padding-top: 1px;
        position: relative;
        &:before {
            background: url(/img/img_shape.png) no-repeat;
            content: "";
            position: absolute;
            top: 0px;
            width: 100%;
            height: 29px;
            background-size: contain;
        }
        @include mq {
            background: none;
            padding-bottom: 104px;
            &:before {
                content: none;
            }
            &:after {
                content: "";
                background-image: url(/img/bg_nagare_pc.png);
                position: absolute;
                top: -70px;
                width: 100%;
                height: 641px;
                z-index: -1;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        flex-direction: row;
        margin-top: 43px;
        @include mq {
            justify-content: center;
            margin-top: 117px;
            width: 920px;
        }
    }
    &__item {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: calc(50% - 10px);
        @include mq {
            width: calc(25% - 16px);
            margin-right: 10px;
        }
        &:nth-child(1),
        &:nth-child(2) {
            margin-bottom: 7px;
            @include mq {
                margin-bottom: 0px;
            }
        }
        &:nth-child(2n) {
            margin-left: 15px;
            @include mq {
                margin-left: 0px;
            }
        }
        &:last-of-type{
            margin-right: 0;
        }
    }
    &__circle {
        align-items: center;
        background-color: #ff4985;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        height: 141px;
        position: relative;
        text-align: center;
        width: 141px;
        @include mq {
            height: 214px;
            width: 214px;
        }
        &--01 {
            &:after {
                content: "";
                background: url(../img/icon_flow_01.png) no-repeat center / cover;
                display: block;
                position: absolute;
                height: 57px;
                left: 0;
                margin: auto;
                top: 70px;
                right: 0;
                width: 75px;
                @include mq {
                    height: 86px;
                    top: 100px;
                    width: 114px;
                }
            }
        }
        &--02 {
            &:after {
                content: "";
                background: url(../img/icon_flow_02.png) no-repeat center / cover;
                display: block;
                position: absolute;
                height: 46px;
                left: 0;
                margin: auto;
                top: 82px;
                right: 0;
                width: 65px;
                @include mq {
                    height: 70px;
                    top: 115px;
                    width: 99px;
                }
            }
        }
        &--03 {
            &:after {
                content: "";
                background: url(../img/icon_flow_03.png) no-repeat center / cover;
                display: block;
                position: absolute;
                height: 51px;
                left: 0;
                margin: auto;
                right: 0;
                top: 75px;
                width: 60px;
                @include mq {
                    height: 78px;
                    top: 100px;
                    width: 92px;
                }
            }
        }
        &--04 {
            &:after {
                content: "";
                background: url(../img/icon_flow_04.png) no-repeat center / cover;
                display: block;
                position: absolute;
                height: 37px;
                left: 0;
                margin: auto;
                right: 0;
                top: 89px;
                width: 71px;
                @include mq {
                    height: 57px;
                    top: 130px;
                    width: 108px;
                }
            }
        }
    }
    &__num {
        height: 17px;
        margin-top: 8px;
        width: 11px;
        @include mq {
            height: 26px;
            width: 20px;
        }
    }
    &__itemttl {
        color: #fff;
        font-size: 17px;
        line-height: 21px;
        padding-top: 18px;
        @include mq {
            font-size: 28px;
            line-height: 31px;
        }
        &--two {
            padding-top: 13px;
        }
        &--span {
            letter-spacing: -0.13em;
            @include mq {
                letter-spacing: -0.16em;
            }
        }
    }
    &__txt {
        font-size: 11px;
        line-height: 12px;
        padding-top: 7px;
        text-align: center;
        width: 100%;
        @include mq {
            font-size: 14px;
            line-height: 21px;
            padding-right: 5px;
            padding-left: 5px;
            letter-spacing: -.05em;
        }
    }
    &__link {
        color: #1879bc;
        text-align: center;
        text-decoration: underline;
    }
    &__btn {
        margin-bottom: 40px;
        @include mq {
            margin-bottom: 100px;
        }
    }
}



.content {
    &__flow {
        padding: 0;
        margin-top: 0;
    }
    &__ttl {
        &--flow {
            margin-bottom: 0px;
            letter-spacing: 0.1em;
        }
    }
}

/************************
------- about -------
************************/
#aboutbridgecareer {
    .page-ttl{
        &__en {
            @include mq {
                width: auto;
            }
        }
        &__ja{
            background-image: url(../img/icon_hand.png);
        }
    }
    .content {
        &__ttl-icon{
            background-image: url(../img/icon_hand.png);
        }
    }
}
