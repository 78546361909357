

.company {
    margin-top: 44px;
    &__container {
        @include container;
        padding: 0;
    }
    &__contents {
        &--first {
            margin: 9px 0 29px;
            @include mq {
                margin: 58px auto 0;
            }
        }
        &--second {
            margin: 16px 12px 0;
            @include mq {
                margin: 35px 96px 90px;
            }
        }
    }
    &__table {
        margin: 0 11px;
        @include mq {
            margin: 0 103px;
        }
    }
    &__cat {
        color: #3eb0ff;
        font-size: 11px;
        line-height: 28px;
        width: 90px;
        @include mq {
            background: #ebf7ff;
            font-size: 16px;
            line-height: 52px;
            padding-left: 20px;
            width: 175px;
        }
    }
    &__item {
        font-size: 11px;
        line-height: 28px;
        @include mq {
            font-size: 16px;
            line-height: 50px;
            padding-left: 30px;
        }
    }
    &__tel {
        @include mq {
            &:nth-child(1) {
                padding-right: 20px;
            }
        }
    }
    &__content {
        @include mq {
            margin-top: 67px;
        }
    }
    &__txt {
        font-size: 11px;
        line-height: 21px;
        @include mq {
            font-size: 15px;
            line-height: 32px;
        }
    }
    &__list {
        margin-top: 20px;
        @include mq {
            margin-top: 31px;
        }
    }
    &__listitem {
        font-size: 11px;
        line-height: 21px;
        @include mq {
            font-size: 15px;
            line-height: 32px;
        }
    }
    &_map{
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        margin-top: 30px;
        iframe {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            }
        }
}
