/************************
  ------- err -------
  ************************/

.err {
  color: #555;
  &__page-ttl {
    &--en {
      height: 20px;
      @include mq {
        height: auto;
      }
    }
    &--ja {
      background-image: url(../img/icon_clock.png);
      @include mq{
        margin-left: 34px;
      }
    }
  }
  &__btn {
    margin-top: 30px;
    margin-bottom: 40px;
    @include mq {
      margin-top: 90px;
      margin-bottom: 100px;
    }
  }
  &__content {
    color: #555;
    text-align: center;
    @include mq {
      font-size: 15px;
      margin-top: 128px;
      margin-bottom: 246px;
    }
  }
}

