$breakpoints: (
  "sm": "screen and (min-width: 400px)",
  "md": "screen and (min-width: 769px)",
  "lg": "screen and (min-width: 1000px)",
  "xl": "screen and (min-width: 1200px)",
  "xxl": "screen and (min-width: 1385px)"
) !default;


@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin container {
  width: 100%;
  padding: 0 12px 0 14px;
  font-size: 11px;
  @include mq {
    max-width: 980px;
    margin: 0 auto;
    padding: 0;
    font-size: 14px;
  }
}

@mixin text {
  font-size: 14px;
  @include mq {
    font-size: 15px;
    letter-spacing: 0.08em;
    line-height: 38px;
  }
}

@mixin ttl2 {
  font-size: 23px;
  color: #3eb0ff;
  display: flex;
  align-items: baseline;
  @include mq {
    font-size: 30px;
  }
  &--ja {
    display: flex;
    color: #333;
    font-size: 13px;
    padding-left: 10px;
    &::before {
      display: block;
      content: "";
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 15px;
      height: 15px;
      @include mq {
        width: 26px;
        height: 26px;
        margin-right: 16px;
        }
    }
    @include mq {
      font-size: 24px;
      margin-left: 20px;
    }
  }
  &--sub{
    display: block;
    font-weight: normal;
    font-size: 12px;
    @include mq {
      font-size: 26px;
    }
  }
}

@mixin ttl3 {
  font-size: 14px;
  color: #7ecef4;
  @include mq {
    font-size: 20px;
  }
}




* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
img {
  max-width: 100%;
  max-height: 100%;
}
ul {
  list-style-type: none;
}

body{
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #333;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  @include mq {
    min-width: 980px;
  }
}
a:hover{
  opacity: 0.8;
}


input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea {
  background-color: #fff;
  color: #666;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 12px 10px;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include mq{
    padding: 3px 10px;
  }
}

select{
  position: relative;
  background-color: #fff;
  background-image: url(../img/icon_arrow_select_bl.jpg);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 3px 24px 3px 5px;
  border-radius: 3px;
  outline: none;
  @include mq{
    padding: 3px 40px 3px 5px;
  }
  &::before {
    position: absolute;
    top: 0.8em;
    right: 0.9em;
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #666666;
    pointer-events: none;
  }
}

select {
  border: 1px solid #ccc;
  padding: 0.4rem 1rem;
  -moz-appearance: none;
  -webkit-appearance: none;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

input{
  max-width: 100%;
}
