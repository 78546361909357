.main {
  @include mq {
    width: 100%;
  }
}

.key {
  padding: 0 0 50px;
  margin: -34px 0 0;
  @include mq {
    padding: 0 0 60px;
    margin: -190px 0 0;
  }
  &__thumbnail {
    &_display {
      &_sp {
        display: block;
        @include mq {
          display: none;
        }
      }
      &_pc {
        display: none;
        @include mq {
          display: block;
          min-height: 607px;
          min-width: 1247px;
          img{
            height: 660px;
            object-fit: cover;
            @media screen and (max-width: 1199px){
              width: 100%;
              height: 607px;
            }
            @media (-ms-high-contrast: none) { height: auto; }
          }
        }
        @media (-ms-high-contrast: none) and (min-width: 769px) { min-height: 607px; }
        @media (-ms-high-contrast: none) and (min-width: 1200px) {  min-height: 654px; }
      }
    }
  }
  &__link {
    display: block;
    margin: calc(-60 / 640 * 100vw) 30px 0;
    @include mq {
      margin: -98px auto 0;
      width: 521px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  &__img {
    display: block;
    width: 100%;
  }
  &__txt {
    position: absolute;
    width: 90%;
    top: 100px;
    left: 0;
    right: 0;
    margin: auto;
    @include mq {
      width: 800px;
      top: 228px;
      left: 0;
      right: 0;
      margin: auto;
    }
    &--pc {
        width: auto;
        max-width: 380px;
        @media screen and (min-width: 1200px) { 
        max-width: 469px;
        }
    }
  }
}

/************************
  ------- search -------
  ************************/

.top-search {
  background: #e0f2fe;
  padding: 40px 0 58px;
  @include mq {
    padding: 75px 0 20px;
  }
  &__container {
    @include container;
  }
  &__title {
    height: 18px;
    margin: 0 0 30px;
    @include mq {
      height: 35px;
      margin: 0 0 60px;
    }
  }
  &__img {
    display: block;
  }
  .search-form {
    margin: 0 0 25px;
    @include mq{
      border-bottom: 1px dotted #c5c5c5;
      margin: 0 0 50px;
    }
    &__submit {
      display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
      @include mq {
      }
    }
  }
}

/************************
  ------- recommend -------
  ************************/

.recommend {
  background: url(../img/top/bg_recommend_01_sp.png) no-repeat top center,
    url(../img/top/bg_recommend_02_sp.png) no-repeat bottom center;
  background-size: contain, contain;
  padding: 50px 0;
  @include mq {
    background: url(../img/top/bg_recommend_01.png) no-repeat top center,
      url(../img/top/bg_recommend_02.png) no-repeat bottom center;
    background-size: contain, contain;
    padding: 120px 0 100px;
  }
  &__container {
    @include container;
  }
  &__ttl {
    height: 18px;
    margin: 0 0 25px;
    @include mq {
      height: 35px;
      margin: 115px 0 60px;
    }
  }
  &__img {
    display: block;
  }
  &__menu {
    align-items: flex-end;
    border-bottom: 1px solid #959595;
    display: flex;
    margin: 0 0 10px;
    @include mq {
      margin: 0 0 22px;
    }
  }
  &__tab-ttl {
    background: #c5c5c5;
    border-radius: 6px 6px 0 0;
    color: #fff;
    display: block;
    flex: 1;
    font-weight: bold;
    height: 26px;
    line-height: 26px;
    max-width: 96px;
    text-align: center;
    cursor: pointer;
    @include mq {
      font-size: 22px;
      height: 46px;
      line-height: 46px;
    }
    &:not(:last-child) {
      margin: 0 2px 0 0;
      @include mq {
        margin: 0 4px 0 0;
      }
    }
    &.active {
      background: #3eb0ff;
      height: 30px;
      line-height: 30px;
      @include mq {
        height: 56px;
        line-height: 56px;
      }
    }
    @include mq {
      width: 60px;
    }
  }
  &__tab-content {
    border-bottom: 1px solid #959595;
    display: none;
  }
  &__link {
    display: none;
    @include mq {
      display: block;
      margin: 20px 0 0 auto;
      width: 210px;
    }
  }
}

.recommend-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 5px;
  @include mq {
    margin: 0 0 10px;
  }
}

.recommend-item {
  background: #e0f2fe;
  border-radius: 3px;
  display: block;
  position: relative;
  margin: 0 0 10px;
  width: calc(50% - 10px);
  @include mq {
    padding: 12px 12px 24px;
    width: 222px;
  }
  &:not(:nth-child(even)) {
    margin: 0 20px 10px 0;
  }
  &:not(:nth-child(4n)) {
    @include mq {
      margin: 0 30px 10px 0;
    }
  }
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 12px 12px 24px;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: auto;
      content: "";
    }
  }
  &__date {
    display: none;
    @include mq {
      display: block;
      color: #666;
      display: inline-block;
      font-size: 15px;
      line-height: 24px;
    }
  }
  &__caregory {
    background: #3eb8b4;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    line-height: 10px;
    margin: 0 0 4px;
    padding: 4px;
    @include mq {
      font-size: 14px;
      line-height: 14px;
      margin: 0 0 0 4px;
      padding: 5px;
    }
  }
  &__tiku {
    color: #3eb0ff;
    font-size: 10px;
    line-height: 14px;
    @include mq {
      font-size: 15px;
      line-height: 22px;
    }
  }
  &__jikyu {
    color: #3eb0ff;
    font-size: 10px;
    line-height: 14px;
    margin: 0 0 4px;
    @include mq {
      font-size: 15px;
      line-height: 22px;
    }
  }
  &__txt {
    color: #1776c2;
    display: block;
    font-size: 10px;
    line-height: 14px;
    text-decoration: underline;
    @include mq {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

/************************
  ------- feature -------
  ************************/

.top-feature {
  background: #e0f2fe;
  padding: 40px 0 50px;
  @include mq {
    padding: 80px 0 0;
  }
  &__container {
    @include container;
    position: relative;
  }
  &__img {
    display: block;
  }
  &__ttl {
    height: 18px;
    margin: 0 0 25px;
    @include mq {
      height: 35px;
      margin: 0 0 50px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    @include mq {
      max-width: none;
    }
  }
}

.top-feature-item {
  display: block;
  flex: none;
  margin: 0 0 14px;
  width: 50%;
  @include mq {
    margin: 0;
    width: 25%;
  }
  &:nth-child(1) {
    padding: 0 7px 0 0;
    @include mq {
      padding: 0 10.5px 0 0;
    }
  }
  &:nth-child(2) {
    padding: 0 0 0 7px;
    @include mq {
      padding: 0 8px 0 2.5px;
    }
  }
  &:nth-child(3) {
    padding: 0 7px 0 0;
    @include mq {
      padding: 0 2.5px 0 8px;
    }
  }
  &:nth-child(4) {
    padding: 0 0 0 7px;
    @include mq {
      padding: 0 0 0 10.5px;
    }
  }
  &__link {
    background: #fff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    display: block;
    height: 100%;
    padding: 0 0 16px;
    position: relative;
    @include mq {
      padding: 0 0 30px;
    }
    &::after {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      right: 0;
      width: 42px;
      height: 42px;
      background-image: url(../img/img_recommend_after.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__img {
    display: block;
    margin: 0 0 10px;
    width: 100%;
    @include mq {
      margin: 0 0 20px;
    }
  }
  &__ttl {
    color: #3eb0ff;
    font-size: 13px;
    font-weight: bold;
    line-height: 17px;
    margin: 0 0 5px;
    padding: 0 10px;
    @include mq {
      font-size: 19px;
      line-height: 29px;
      padding: 0 30px;
    }
  }
  &__txt {
    font-size: 10px;
    font-weight: bold;
    line-height: 14px;
    padding: 0 10px;
    @include mq {
      font-size: 14px;
      line-height: 24px;
      padding: 0 30px;
    }
  }
}

/************************
  ------- strength -------
  ************************/

.strength {
  background: linear-gradient(#e0f2fe 50%, #fff 50%);
  margin: 0 0 50px;
  @include mq {
    margin: 0 0 120px;
  }
  &__thumbnail {
    &_display {
      &_sp {
        display: block;
        @include mq {
          display: none;
        }
      }
      &_pc {
        display: none;
        @include mq {
          display: block;
        }
      }
    }
  }
  &__link {
    display: block;
    margin: calc(-60 / 640 * 100vw) 30px 0;
    @include mq {
      margin: -50px auto 0;
      width: 521px;
    }
  }
  &__img {
    display: block;
    width: 100%;
  }
}

/************************
  ------- category-search -------
  ************************/

.category-search {
  margin: 0 0 60px;
  position: relative;
  @include mq {
    margin: 0 0 120px;
  }
  &__container {
    @include container;
    @include mq {
      background: url(../img/bg_category_search.png) no-repeat right 30px center;
      background-size: contain;
    }
  }
  &__box {
    border-bottom: 2px solid #ccc;
    padding: 10px 0 0;
    @include mq {
      border-bottom-width: 3px;
      padding: 16px 0 0;
    }
    &:first-child {
      border-top: 2px solid #ccc;
      @include mq {
        border-top-width: 3px;
      }
    }
  }
  &__menu {
    font-size: 13px;
    font-weight: bold;
    line-height: 13px;
    margin: 0 0 10px;
    @include mq {
      font-size: 20px;
      line-height: 20px;
      margin: 0 0 16px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 4px;
    padding: 0 0 0 16px;
    @include mq {
      margin: 0 0 16px;
      padding: 0 0 0 24px;
      font-size: 16px;
    }
  }
}
.category-search-item {
  margin: 0 12px 6px 0;
  @include mq {
    margin: 0 20px 4px 0;
  }
  &__link {
    background: rgba(62, 176, 255, 0.2);
    border-radius: 3px;
    color: #3eb0ff;
    display: block;
    font-size: 10px;
    line-height: 16px;
    padding: 0 6px;
    @include mq {
      border-radius: 6px;
      font-size: 15px;
      line-height: 20px;
      padding: 4px 8px;
    }
  }
}
